import React from 'react'
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";

export default function Drass() {
  return (
    <>
      <Main img="https://images.justwatch.com/poster/256743734/s718/season-2.jpg" name="Demon Slayer" s="2" e="18" />

      <Ep ep="S2-E1 Demon Slayer" s="S2-E1" link="https://drive.google.com/open?id=1dFdHBpxGELaIdrqsW5yddsktcNWvEeRQ&usp=drive_copy" />
      <Ep ep="S2-E2 Demon Slayer" s="S2-E2" link="https://drive.google.com/open?id=1AASZMxj8rvj169t3Z1frEBIEo57NEr6s&usp=drive_copy" />
      <Ep ep="S2-E3 Demon Slayer" s="S2-E3" link="https://drive.google.com/open?id=1KYVr-jzabQUdUu4vg6_ZtXJ6yj0-2Sph&usp=drive_copy" />
      <Ep ep="S2-E4 Demon Slayer" s="S2-E4" link="https://drive.google.com/open?id=1XksQ9Tqv2wcOkqrTveW8CiGKcF9GNQH2&usp=drive_copy" />
      <Ep ep="S2-E5 Demon Slayer" s="S2-E5" link="https://drive.google.com/open?id=1_Um-lVy7B3jCP_q7zgfeHLnn_FN_aB8n&usp=drive_copy" />
      <Ep ep="S2-E6 Demon Slayer" s="S2-E6" link="https://drive.google.com/open?id=1E7vXKoGDZ74kYrweUdlLfQMmITSKoqvo&usp=drive_copy" />
      <Ep ep="S2-E7 Demon Slayer" s="S2-E7" link="https://drive.google.com/open?id=1bNsGSWRIV9Fjssg2vSrfOOqXfdS9Y-wn&usp=drive_copy" />
      <Ep ep="S2-E8 Demon Slayer" s="S2-E8" link="https://drive.google.com/open?id=1WYwQ338J9ICy-17pd37YkytRGS_rFCUh&usp=drive_copy" />
      <Ep ep="S2-E9 Demon Slayer" s="S2-E9" link="https://drive.google.com/open?id=1rW9ztnu43cRyEOHpbFZRytOsG_YkOawp&usp=drive_copy" />
      <Ep ep="S2-E10 Demon Slayer" s="S2-E10" link="https://drive.google.com/open?id=173w8UUHcANGCZ5KLhPfD_J7Padsn6mnG&usp=drive_copy" />
      <Ep ep="S2-E11 Demon Slayer" s="S2-E11" link="https://drive.google.com/open?id=1Y0_WT3QiODgz6cH4KUfHJiImJuK4mI9l&usp=drive_copy" />
      <Ep ep="S2-E12 Demon Slayer" s="S2-E12" link="https://drive.google.com/open?id=11xwcKIYFjbKLdQtGU9HIDGQAh_iv60Zm&usp=drive_copy" />
      <Ep ep="S2-E13 Demon Slayer" s="S2-E13" link="https://drive.google.com/open?id=1UQ844VLu6N84WjhL7Wfj-9HR39d1XAD2&usp=drive_copy" />
      <Ep ep="S2-E14 Demon Slayer" s="S2-E14" link="https://drive.google.com/open?id=1iz8mEM0h0pS3jY9upecpXsOYgHts0PA8&usp=drive_copy" />
      <Ep ep="S2-E15 Demon Slayer" s="S2-E15" link="https://drive.google.com/open?id=1ZZezoZYlNftFVYLe9SfxR7Zl6h0bKBRt&usp=drive_copy" />
      <Ep ep="S2-E16 Demon Slayer" s="S2-E16" link="https://drive.google.com/open?id=1w8w-c9k6TegbM46DgfCuzyJg7KaIWzd1&usp=drive_copy" />
      <Ep ep="S2-E17 Demon Slayer" s="S2-E17" link="https://drive.google.com/open?id=1uJMnhmxeziOjp_uDg3szsI6O7Fny9ji9&usp=drive_copy" />
      <Ep ep="S2-E18 Demon Slayer" s="S2-E18" link="https://drive.google.com/open?id=117wc-5xldmr_xVu0Fhgo-jugPRfvgMg4&usp=drive_copy" />
      <Animeover />
      <Footer />
    </>
  )
}

import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";

import Footer from "../components/Footer";
import Complete from "../Structure/Complete";
export default function Assassintionclasss2() {
  return (
    <>
    <Main img="https://i.postimg.cc/8z6Bm2PN/o-Te-MBICUAL2d-JVd-B11-B45y-TCGg5.jpg" name="Assassination Classroom" s="2" e="22" />

      <Ep ep="S2-E1 Assassination Classroom" s="S2-E1" link="https://drive.google.com/open?id=1qcf_qkzzyCkekOv1BzP7Ir8JCpBN754V&usp=drive_copy" />
      <Ep ep="S2-E2 Assassination Classroom" s="S2-E2" link="https://drive.google.com/open?id=1iFNt1CepzBijMwFI4e3WYquq_UHAIxZg&usp=drive_copy" />
      <Ep ep="S2-E3 Assassination Classroom" s="S2-E3" link="https://drive.google.com/open?id=1bPcIqrp0jJB5tD-Zs1mphv4iO3XdwAkX&usp=drive_copy" />
      <Ep ep="S2-E4 Assassination Classroom" s="S2-E4" link="https://drive.google.com/open?id=1brHWqOubY9BClc8vMm9_SsrBFlj0zOR-&usp=drive_copy"/>
      <Ep ep="S2-E5 Assassination Classroom" s="S2-E5" link="https://drive.google.com/open?id=1eys3k9c_ZH2WiZ12oL0cd0JPlQvn2rGo&usp=drive_copy"/>
      <Ep ep="S2-E6 Assassination Classroom" s="S2-E6" link="https://drive.google.com/open?id=1wDMb24Nk4p_r6bkOUSCvYzvg4YUCVwWZ&usp=drive_copy"/>
      <Ep ep="S2-E7 Assassination Classroom" s="S2-E7" link="https://drive.google.com/open?id=1p8rrIX8H5ee-R9ctrgenoVsMii57HWmr&usp=drive_copy"/>
      <Ep ep="S2-E8 Assassination Classroom" s="S2-E8" link="https://drive.google.com/open?id=1zLmn5TuI4Qs3wZd1CiFz2PajdkNjz2H-&usp=drive_copy"/>
      <Ep ep="S2-E9 Assassination Classroom" s="S2-E9" link="https://drive.google.com/open?id=1QUuiW9XSM5dY0avoQiK8slRJ5ZO5zIql&usp=drive_copy"/>
      <Ep ep="S2-E10 Assassination Classroom" s="S2-E10" link="https://drive.google.com/open?id=1z4VmE8Ml_33gBliife2bjtLOiCjwFXjF&usp=drive_copy"/>
      <Ep ep="S2-E11 Assassination Classroom" s="S2-E11" link="https://drive.google.com/open?id=1oCDY_2hIE9CU6mSYb-o_uwJA2NgRHsEN&usp=drive_copy"/>
      <Ep ep="S2-E12 Assassination Classroom" s="S2-E12" link="https://drive.google.com/open?id=1iYI1QOPXE6T2bg66I9uUJsp4HzI7uWvR&usp=drive_copy"/>
      <Ep ep="S2-E13 Assassination Classroom" s="S2-E13" link="https://drive.google.com/open?id=11l81OmYYJ5CJeF8hjloz8VC4j0jngARq&usp=drive_copy"  />
      <Ep ep="S2-E14 Assassination Classroom" s="S2-E14" link="https://drive.google.com/open?id=1JurbY7ozB8ZrCtNkTtDGycN0OeVHUCwA&usp=drive_copy"  />
      <Ep ep="S2-E15 Assassination Classroom" s="S2-E15" link="https://drive.google.com/open?id=1rgNbgxgOkHXvKd1nC-MyDOUB0o4GuI2N&usp=drive_copy"  />
      <Ep ep="S2-E16 Assassination Classroom" s="S2-E16" link="https://drive.google.com/open?id=17uXfc3vuC07CdP6EQwSQLyRWKBwqC8oL&usp=drive_copy"  />
      <Ep ep="S2-E17 Assassination Classroom" s="S2-E17" link="https://drive.google.com/open?id=1AlujjE6D7LV_Ux118AB7idqGTMR2JD0g&usp=drive_copy"  />
      <Ep ep="S2-E18 Assassination Classroom" s="S2-E18" link="https://drive.google.com/open?id=1A593PS4_dHmbJ7FkyzvX63iqLMYuLh0R&usp=drive_copy"  />
      <Ep ep="S2-E19 Assassination Classroom" s="S2-E19" link="https://drive.google.com/open?id=1Nd-eSTRuTrTHAlDQbJW687tC9H_xTT8L&usp=drive_copy"  />
      <Ep ep="S2-E20 Assassination Classroom" s="S2-E20" link="https://drive.google.com/open?id=1v63gxpksyNIiEVfIWrKrxkPyJ2SO_zU-&usp=drive_copy"  />
      <Ep ep="S2-E21 Assassination Classroom" s="S2-E21" link="https://drive.google.com/open?id=18YV18FioZ-p4EtEyrAqC3bKXwHESyk6C&usp=drive_copy"  />
      <Ep ep="S2-E22 Assassination Classroom" s="S2-E22" link="https://drive.google.com/open?id=15yhNtVwPpWw2Wt_kowr2y1fFf9PNBdgY&usp=drive_copy"  />
      <Ep ep="S2-E23 Assassination Classroom" s="S2-E23" link="https://drive.google.com/open?id=1htaavxq1F3_FyEijFzjQzSTMY7QOiIpD&usp=drive_copy"  />
      <Ep ep="S2-24 Assassination Classroom" s="S2-E24" link="https://drive.google.com/open?id=14Y7hbXNOl1d6px4P4-u1y7Fe1YQmTHss&usp=drive_copy"  />
      <Ep ep="S2-E25 Assassination Classroom" s="S2-E25" link="https://drive.google.com/open?id=1ItS02BKQhZJNPPWM4MiqBg03FsJw8Cr2&usp=drive_copy"  />
    <Complete/>
    <Footer />
  </>
  )
}

import React from 'react'

import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";

export default function Onepunch() {
  return (
    <>
 <Main img="https://i.postimg.cc/qqnJFC9m/MV5-BZj-Jl-Nz-E5-Yz-Et-Yz-Qw-YS00-NTBj-LTk5-Yz-At-Yz-Uw-OWQy-M2-E3-OGI2-Xk-Ey-Xk-Fqc-Gde-QXVy-NTgy-NTA4-Mj-M-V1-FMjpg-UX1000.jpg" name="One punch Man" s="1" e="" />
 
 <Ep ep="S1-E1 One Punch Man" s="S1-E1" link="https://drive.google.com/open?id=1ZhvxuaYvhE-CsuprGBEIY21LTORWLWUm&usp=drive_copy"/>
<Ep ep="S1-E2 One Punch Man" s="S1-E2" link="https://drive.google.com/open?id=1phmEttQxx8mfRWdn3K56UuTLF0UHB-aU&usp=drive_copy"/>
<Ep ep="S1-E3 One Punch Man" s="S1-E3" link="https://drive.google.com/open?id=1eZrrEFOHiDQKivIIyfDoFZFDlWkuDAsJ&usp=drive_copy"/>
<Ep ep="S1-E4 One Punch Man" s="S1-E4" link="https://drive.google.com/open?id=1ckCEbdGVAFzv3OcTH5XhQH7-qMRKURDy&usp=drive_copy"/>
<Ep ep="S1-E5 One Punch Man" s="S1-E5" link="https://drive.google.com/open?id=15lhifN_Xd8LOSFEoBPaZXSJzxuB4PTEx&usp=drive_copy"/>
<Ep ep="S1-E6 One Punch Man" s="S1-E6" link="https://drive.google.com/open?id=1eGw9NVsXi_dlAkE-vvPXWpSqxkNgcEyn&usp=drive_copy"/>
<Ep ep="S1-E7 One Punch Man" s="S1-E7" link="https://drive.google.com/open?id=16i4zRbhHr04sPr19asF7QFzcMsOLA8kb&usp=drive_copy"/>
<Ep ep="S1-E8 One Punch Man" s="S1-E8" link="https://drive.google.com/open?id=13PZeR6wqsui2mTIyckBc4oYHC9T6YJ2n&usp=drive_copy"/>
<Ep ep="S1-E9 One Punch Man" s="S1-E9" link="https://drive.google.com/open?id=1YZrTa1druwl7qh3rFzq60F7EKC94ODIT&usp=drive_copy"/>
<Ep ep="S1-E10 One Punch Man" s="S1-E10" link="https://drive.google.com/open?id=1zGR81qm2XB0B1CFp4CipmtbDWxA7fn3X&usp=drive_copy"/>
<Ep ep="S1-E11 One Punch Man" s="S1-E11" link="https://drive.google.com/open?id=1X0xIB6PEr175p46G77AF_VvJOYDQKwp_&usp=drive_copy"/>
<Ep ep="S1-E12 One Punch Man" s="S1-E12" link="https://drive.google.com/open?id=10ZXwtYG7s-buXIl_oQozTvUm80UgXMui&usp=drive_copy"/>

 <Animeover/>
<Footer/>
</>
  )
}

import React from "react";

export default function Footer() {
  return (
    <>
      <footer class="bg-[#1e0a3c] h-14 flex justify-center items-center mt-10">
        <p class="text-white">© Hin-movies</p>
      </footer>
    </>
  );
}

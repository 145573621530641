import React from 'react'
import "./Animeover.css";

export default function Complete() {
  return (
    <>
      <div className="ep-over">
        <p>
          <b> The Anime is Completes 😊</b>
        </p>
      </div>
    </>
  )
}

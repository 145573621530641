import React from 'react'

import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";

export default function Demon3() {
  return (
    <>
    <Main img="https://i.postimg.cc/SNtxTk2Y/64490a8ddaabca001da8fe61.jpg" name="Demon Slayer" s="3" e="11" />

    <Ep ep="S3-E1 Demon Slayer" s="S3-E1" link="https://drive.google.com/open?id=1biL3bs-qdfYMWjO8Q2vJOLnlP1QTlge-&usp=drive_copy" />
<Ep ep="S3-E2 Demon Slayer" s="S3-E2" link="https://drive.google.com/open?id=1a-4ObF98b1b_1_njKRx7jTwJ4gJFdurt&usp=drive_copy" />
<Ep ep="S3-E3 Demon Slayer" s="S3-E3" link="https://drive.google.com/open?id=19U3FAZMaobOznn2pyMXJbziQO8fUke4d&usp=drive_copy" />
<Ep ep="S3-E4 Demon Slayer" s="S3-E4" link="https://drive.google.com/open?id=1TFGATt7ZG6ua51ZLVyH1VuzNh9ERfzEP&usp=drive_copy" />
<Ep ep="S3-E5 Demon Slayer" s="S3-E5" link="https://drive.google.com/open?id=1Z86HPFO13vLreJEnwuGz23-PVWUS-Nwt&usp=drive_copy" />
<Ep ep="S3-E6 Demon Slayer" s="S3-E6" link="https://drive.google.com/open?id=1ooAYXigjwoIYGLeEEyOMPU4BOSdxHAeQ&usp=drive_copy" />
<Ep ep="S3-E7 Demon Slayer" s="S3-E7" link="https://drive.google.com/open?id=1FQGldb1SuD7TFa8VIg-2ifJiHmp14ruB&usp=drive_copy" />
<Ep ep="S3-E8 Demon Slayer" s="S3-E8" link="https://drive.google.com/open?id=16v-fH7ou_mj-VqtV_FmI7ljRaXVNV3AX&usp=drive_copy" />
<Ep ep="S3-E9 Demon Slayer" s="S3-E9" link="https://drive.google.com/open?id=1YbwSUMrYdSb1IzEAQOiNNdekmtEo5_6J&usp=drive_copy" />
<Ep ep="S3-E10 Demon Slayer" s="S3-E10" link="https://drive.google.com/open?id=1BuHIZx3V8qsXkJzGZ7iW20ChcHFIMuNG&usp=drive_copy" />
<Ep ep="S3-E11 Demon Slayer" s="S3-E11" link="https://drive.google.com/open?id=1wEbs_Kmuk2QfJH4u75uCMyu9ZOzE3h6Z&usp=drive_copy" />
<Animeover/>
<Footer/>
    </>
  )
}

import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";

import Footer from "../components/Footer";
import Complete from "../Structure/Complete";
export default function Sololeveling() {
  return (
    <>
      <Main img="https://i.postimg.cc/MZs116wh/75c-ENUBSmn-ON8-IBDc2-F979-CMus-N.jpg" name="Solo Leveling" s="1" e="12" />

      <Ep ep="S1-E1 Solo Leveling" s="S1-E1" link="https://drive.google.com/open?id=1i_6Z_3kshPdORMrrcWdq-Awf9SJ4-gte&usp=drive_copy" />
      <Ep ep="S1-E2 Solo Leveling" s="S1-E2" link="https://drive.google.com/open?id=1WXszEZp25Z1k6X3Smfk-UAQXn5dHO3Ds&usp=drive_copy" />
      <Ep ep="S1-E3 Solo Leveling" s="S1-E3" link="https://drive.google.com/open?id=1lGS3YdHNHnDK9dNRvBGYVGOM4Xzx5mgT&usp=drive_copy" />
      <Ep ep="S1-E4 Solo Leveling" s="S1-E4" link="https://drive.google.com/open?id=1IRU9RDoAh3FwD8ZQ7M1MBd-CRi9JR_lj&usp=drive_copy"/>
      <Ep ep="S1-E5 Solo Leveling" s="S1-E5" link="https://drive.google.com/open?id=1q9PMpQ8Xlak5Tiq_JQ7CC76VOxSBIzzX&usp=drive_copy"/>
      <Ep ep="S1-E6 Solo Leveling" s="S1-E6" link="https://drive.google.com/open?id=1gNPyAaGzRbqi8ocvaMhCIRlUblzcUR2g&usp=drive_copy"/>
      <Ep ep="S1-E7 Solo Leveling" s="S1-E7" link="https://drive.google.com/open?id=1l12AAhXGnKlTHFzlZ_wTVdVF5_h1NfXA&usp=drive_copy"/>
      <Ep ep="S1-E8 Solo Leveling" s="S1-E8" link="https://drive.google.com/open?id=1kcOKCztBTVIJx3ed9UaA2AH_JRFEHc0R&usp=drive_copy"/>
      <Ep ep="S1-E9 Solo Leveling" s="S1-E9" link="https://drive.google.com/open?id=1q2W0wASQHKz1AOOxpTQqTm941jbtaZCS&usp=drive_copy"/>
      <Ep ep="S1-E10 Solo Leveling" s="S1-E10" link="https://drive.google.com/open?id=17qt2ah5HCD8bnM67mYQJoLRpGJwS2bsz&usp=drive_copy"/>
      <Ep ep="S1-E11 Solo Leveling" s="S1-E11" link="https://drive.google.com/open?id=1FwF1ettWopU4UEA1XkIv4YcYfFRRZs51&usp=drive_copy"/>
      <Ep ep="S1-E12 Solo Leveling" s="S1-E12" link="https://drive.google.com/open?id=1TnbmxDlgCPQ28ooHYmSR39J1frVhSTRw&usp=drive_copy"/>
     <Complete/>
      <Footer />
    </>
  )
}

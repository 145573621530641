import React from 'react'
import Home from "./Home";
import Footer from "./Footer";

export default function AnimeMovies() {
  return (
   <>
   <Home
        imd1="8.6"
        link1="../"
        img1="https://i.postimg.cc/wvHMM45Q/MV5-BNThi-Zj-A3-Mj-It-ZGY5-Ni00-Zm-Jh-LWEw-N2-Et-OTBl-YTA4-Y2-E0-M2-Zm-Xk-Ey-Xk-Fqc-Gc-V1.jpg"
        name1="Spider-Man Across the Spider-Verse"
        // 2 start
        imd2="7.1"
        link2="https://drive.google.com/open?id=1h8wCB6UbCtcHMAbRhY56Gk4Vaf5FpUUU&usp=drive_copy"
        img2="https://i.postimg.cc/nzkzDK0b/MV5-BODFk-MGM2-OGUt-MTAy-Ny00-MDNj-LThk-YTAt-MDVm-Mz-E3-Ym-U5-NWY4-Xk-Ey-Xk-Fqc-Gde-QXVy-MTA3-MDk2-NDg2-V1-FMjpg-UX1000.jpg"
        name2="Shin chan mystery of tenkasu academy"
        // 3 start
        imd3="6.5"
        link3="https://drive.google.com/open?id=18r_Htid9EE_IgQ0zOQnkRpeucuW8Qrcb&usp=drive_copy"
        img3="https://i.postimg.cc/y6Lx6PD0/MV5-BMDcz-N2-Fj-NDQt-MGZh-MC00-Njlj-LWE2-Zj-Qt-Yz-U5-YWQ5-Zj-A0-OTIx-Xk-Ey-Xk-Fqc-Gde-QXVy-NTk5-ODg4-NDA-V1-FMjpg-UX1000.jpg"
        name3="Shin-chan Rakuga Kingdom"
        // 4 start
        imd4="8.4"
        link4="https://drive.google.com/file/d/15pdMy_IvGWFfu3MOz4xLfLORMVqHYvo2/view?usp=sharing"
        img4="https://i.postimg.cc/ZqqHvjTp/MV5-BODRm-ZDVm-Nz-Ut-ZDA4-ZC00-Njhk-LWI2-M2-Ut-N2-M0-ZDIz-NDcx-YThj-L2lt-YWdl-Xk-Ey-Xk-Fqc-Gde-QXVy-NTk0-Mz-Mz-ODA-V1-FMjpg-U.jpg"
        name4="Your Name Fan Dubbed "
      />
      {/*home 2 row */}

      <h1 className="">Soon... we will add more</h1>
      

      

      <Footer />
   </>
  )
}

import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";
export default function Byebye() {
  return (
    <>
    <Main img="https://i.postimg.cc/qRfFGcdZ/bye-bye-earth.jpg" name="Bye Bye, Earth" s="1" e="12" />

    <Ep ep="S1-E1 Bye Bye, Earth" s="S1-E1 " link="https://drive.google.com/open?id=13Zfzawedlb6pp1CCoN7FRb4TMjDFmt-D&usp=drive_copy" />
      <Ep ep="S1-E2 Bye Bye, Earth" s="S1-E2 " link="https://drive.google.com/open?id=1yxL2734_FxP1sZvh62yF6042z-7ya_HU&usp=drive_copy" />
      <Ep ep="S1-E3 Bye Bye, Earth" s="S1-E3 " link="https://drive.google.com/open?id=1eyCRJK5sYx8O0t8MBiY8ft1qeL0LPW6w&usp=drive_copy" />
      <Ep ep="S1-E4 Bye Bye, Earth" s="S1-E4 " link="https://drive.google.com/open?id=12iFyW16AkvXLV3zrwDIXMek33dsLCNUE&usp=drive_copy"/>
      <Ep ep="S1-E5 Bye Bye, Earth" s="S1-E5 " link="https://drive.google.com/open?id=13uxjHjtleUdPUm8padNV-4266RQKH_nV&usp=drive_copy"/>
      <Ep ep="S1-E6 Bye Bye, Earth" s="S1-E6 " link="https://drive.google.com/open?id=1Tpl6VSLjbOrHyqjayJP54hfau13S0tqX&usp=drive_copy"/>
      <Ep ep="S1-E7 Bye Bye, Earth New!" s="S1-E7 " link="https://drive.google.com/file/d/1gebSG7E-O5IXZGU77OdZBTLlbe7OoVW7/view?usp=sharing"/>
       {/* <Ep ep="S1-E8 " s="S1-E8 Bye Bye, Earth" link=""/>
      <Ep ep="S1-E9 " s="S1-E9 Bye Bye, Earth" link=""/>
      <Ep ep="S1-E10 " s="S1-E10 Bye Bye, Earth" link=""/>
      <Ep ep="S1-E11 " s="S1-E11 Bye Bye, Earth" link=""/>
      <Ep ep="S1-E12 " s="S1-E12 Bye Bye, Earth" link=""/> */}
    <Animeover />
    <Footer />
  </>
  )
}

import React from "react";
import "./Animeover.css";

export default function Animeover() {
  return (
    <>
      <div className="ep-over">
        <p>
          <b> Episode Over 😊</b>
        </p>
      </div>
    </>
  );
}

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./components/About";
import Navbar from "./components/Navbar";
import Movies from "./components/Movies";
import SearchBar from "./components/SearchBar";
import ScrollUpButton from "./components/ScrollUpButton";

import Jjk from "./Anime/Jjk";
import Mash from "./Anime/Mash"
import Bluelock from "./Anime/Bluelock"
import Assassintionclasss1 from "./Anime/Assassintionclasss1"
import Assassintionclasss2 from "./Anime/Assassintionclasss2";
import Byebye from "./Anime/Byebye";
import Dayswithmyst from "./Anime/Dayswithmyst";
import Drstone from "./Anime/Drstone";
import Fairytail from "./Anime/Fairytail";
import Haikyu from "./Anime/Haikyu";
import Kaijuno8 from "./Anime/Kaijuno8";
import Loghorizon from "./Anime/Loghorizon";
import Makeine from "./Anime/Makeine";
import Nolonger from "./Anime/Nolonger";
import Quality from "./Anime/Quality";
import Sololeveling from "./Anime/Sololeveling";
import Thestrongestma from "./Anime/Thestrongestma";
import Towerofgod from "./Anime/Towerofgod";
// import Towerofgods2 from "./Anime/Towerofgods2";
import Turebeauty from "./Anime/Turebeauty";
import Twilinghtout from "./Anime/Twilinghtout";
import Viral from "./Anime/Viral";
import Vtuberlegend from "./Anime/Vtuberlegend";
import Whydoes from "./Anime/Whydoes";
import Wistoria from "./Anime/Wistoria";
import Drass from "./Anime/Drass";
import Alya from "./Anime/Alya";
import Horim from "./Anime/Horim";
import Jjk2 from "./Anime/Jjk2";
import AnimeMovies from "./components/AnimeMovies";
import Spy from "./Anime/Spy";
import Musoko from "./Anime/Musoko";
import Demon3 from "./Anime/Demon3";
import Demon4 from "./Anime/Demon4";
import Anarchdemon from "./Anime/Anarchdemon";
import Naruto from "./Anime/Naruto";
import Wind from "./Anime/Wind";
import Rankof from "./Anime/Rankof";
import Akedi from "./Anime/Akedi";
import Blackrock from "./Anime/Blackrock";
import Onepunch from "./Anime/Onepunch";
import Tell from "./Structure/Tell";
import Chanso from "./Anime/Chanso";
import Igotchat from "./Anime/Igotchat";







function App() {
  return (
    <>
      <Navbar />
      <SearchBar />
      <Tell/>
     
      <ScrollUpButton />

      <Routes>
        <Route exact path="/" element={<Movies />} />
        <Route exact path="/home" element={<Movies />} />
        <Route exact path="/movies" element={<AnimeMovies/>} />
        <Route exact path="/about" element={<About />} />
        {/* Anime Movies link start 😃🧐 */}

        
        
        <Route exact path="/Mashle : Magic and Muscles Season 2" element={<Mash />} />
        <Route exact path="/BLUE LOCK Season 1" element={<Bluelock/>} />
        <Route exact path="/Assassintion Classroom season 1" element={<Assassintionclasss1 />} />
        <Route exact path="/Assassination Classroom season 2" element={<Assassintionclasss2 />}     />
        <Route exact path="/Alya Sometimes Hides Her Feelings in Russian" element={<Alya/>} />
        <Route exact path="/Bye Bye, Earth" element={< Byebye/>}     />
        <Route exact path="/Days with My Stepsister Season 1 Hindi Dubbed" element={< Dayswithmyst/>}     />
        <Route exact path="/Dr. STONE Season 2" element={< Drstone/>}     />
        <Route exact path="/Fairy Tail: 100 Years Quest" element={< Fairytail/>}     />
        <Route exact path="/Haikyu!! Season 1 Hindi Dubbed" element={< Haikyu/>}     />
        <Route exact path="/Kaiju No. 8 Season 1 Hindi Dubbed" element={< Kaijuno8/>}     />
        <Route exact path="/Log Horizon Season 1 Hindi Dubbed" element={< Loghorizon/>}     />
        <Route exact path="/Makeine: Too Many Losing Heroines! Hindi Dubbed" element={< Makeine/>}     />
        <Route exact path="/No Longer Allowed in Another World Season 1 Hindi Dubbed" element={< Nolonger/>}     />
        <Route exact path="/Quality Assurance in Another World Hindi Dubbed" element={< Quality/>}     />
        <Route exact path="/Solo Leveling Season 1 Hindi Dubbed" element={< Sololeveling/>}     />
        <Route exact path="/The Strongest Magician in the Demon Lord’s Army Was a Human Season 1 Hindi Dubbed" element={< Thestrongestma/>}     />
        <Route exact path="/Tower of God season 2 Hindi Dubbed" element={< Towerofgod/>}     />
        {/* <Route exact path="/Tower of God Season 2 Hindi Dubbed" element={< Towerofgods2 />}     /> */}
        <Route exact path="/True Beauty Season 1 Hindi Dubbed" element={< Turebeauty/>}     />
        <Route exact path="/Twilight Out of Focus Season 1 Hindi Dubbed" element={<  Twilinghtout/>}     />
        <Route exact path="/Viral Hit Season 1 Hindi Dubbed" element={< Viral/>}     />
        <Route exact path="/VTuber Legend: How I Went Viral After Forgetting to Turn Off My Stream Hindi Dubbed" element={< Vtuberlegend/>}     />
        <Route exact path="/Why Does Nobody Remember Me In This World Hindi Dubbed" element={< Whydoes/>}     />
        <Route exact path="/Wistoria: Wand and Sword Hindi Dubbed" element={< Wistoria/>}     />
        <Route exact path="/demon slayer season 2" element={<Drass/>}  />
        <Route exact path="/Horimiya: The Missing Pieces premiered" element={<Horim/>}  />
        <Route exact path="/Jujutsu kaisen season 2 Hindi Dubbed" element={< Jjk/>}     />
        <Route exact path="/Jujutsu kaisen season 1 Hindi Dubbed" element={< Jjk2/>}     />
        <Route exact path="/spy x family season 2 hindi dubbed" element={<Spy />}     />
        <Route exact path="/Mushoku Tensei Jobless Reincarnation" element={< Musoko/>}     />
        <Route exact path="/Demon Slayer season 3 hindi dubbed" element={< Demon3/>}     />
        <Route exact path="/Demon Slayer season 4 hindi dubbed" element={< Demon4/>}     />
        <Route exact path="/An Archdemon's Dilemma: How to Love Your Elf Bride Season 1" element={< Anarchdemon/>}     />
        <Route path="/Mashle : Magic and Muscles Season 2" element={< Mash/>} /> 
        <Route exact path="/naruto season 1 hindi dubbed" element={< Naruto/>}     /> 
        <Route exact path="/Wind Breaker season 1 hindi dubbed" element={<Wind />}     />
        <Route exact path="/Ranking of Kings season 1" element={< Rankof/>}     /> 
        <Route exact path="/Akebi's Sailor Uniform season 1" element={< Akedi/>}     />
        <Route exact path="/Black Rock Shooter: Dawn Fall season 1" element={< Blackrock/>}     />
        <Route exact path="/one punch man season 1 hindi dubbed" element={< Onepunch/>}     />
        <Route exact path="/Chainsaw Man season 1 hindi dubbed" element={< Chanso/>}     />
        <Route exact path="/I Got a Cheat Skill in Another World" element={< Igotchat/>}     />
        {/* <Route exact path="/" element={< />}     /> */}
        
      </Routes>
    </>
  );
}

export default App;

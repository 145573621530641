import React from 'react'

import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";

export default function Naruto() {
    return (
        <>
            <Main img="https://i.postimg.cc/nzgtKcwn/images.jpg" name="Naruto" s="1" e="26" />

            <Ep ep="S1-E1 Naruto - Hinanime" s="S1-E1" link="https://drive.google.com/open?id=1Qa_umhCRnsWnWfLml7oAhIVBWyFeWkJX&usp=drive_copy" />
            <Ep ep="S1-E2 Naruto - Hinanime" s="S1-E2" link="https://drive.google.com/open?id=1a96jPlz2xp0zXuNG35ZvcAE0o1HpWDs0&usp=drive_copy" />
            <Ep ep="S1-E3 Naruto - Hinanime" s="S1-E3" link="https://drive.google.com/open?id=1gSoaQkXqwe-Wi1KKoNPFTkP9wWVfAZA6&usp=drive_copy" />
            <Ep ep="S1-E4 Naruto - Hinanime" s="S1-E4" link="https://drive.google.com/open?id=1SYFWnCQEww0xe0aAKL4tyVqVorSQDKtN&usp=drive_copy" />
            <Ep ep="S1-E5 Naruto - Hinanime" s="S1-E5" link="https://drive.google.com/open?id=1H5MYx6l5JMAgkuXTDi_ONP_0XTgEPL57&usp=drive_copy" />
            <Ep ep="S1-E6 Naruto - Hinanime" s="S1-E6" link="https://drive.google.com/open?id=10hU-ybYjZaoJCSWQFIvH_IKKJ9fdHq63&usp=drive_copy" />
            <Ep ep="S1-E7 Naruto - Hinanime" s="S1-E7" link="https://drive.google.com/open?id=1w3WuUl_gho1wjKVKHajLNx_fYFqfTeMG&usp=drive_copy" />
            <Ep ep="S1-E8 Naruto - Hinanime" s="S1-E8" link="https://drive.google.com/open?id=1gsHY8EmlXrgOOI0fvXhz6_RitxThN21u&usp=drive_copy" />
            <Ep ep="S1-E9 Naruto - Hinanime" s="S1-E9" link="https://drive.google.com/open?id=1hi15KCJySCBOnDbGWBt8ZRUm27-qaiUj&usp=drive_copy" />
            <Ep ep="S1-E10 Naruto - Hinanime" s="S1-E10" link="https://drive.google.com/open?id=1aA0uzV2AQlNzY-fmePjXyNsw1rGVGN8h&usp=drive_copy" />
            <Ep ep="S1-E11 Naruto - Hinanime" s="S1-E11" link="https://drive.google.com/open?id=1G9a_dq_yqgzzA2dsU51lRgwba4ZfWGMe&usp=drive_copy" />
            <Ep ep="S1-E12 Naruto - Hinanime" s="S1-E12" link="https://drive.google.com/open?id=1a86gWPlOHte_btS_CyiOtkDbVj9LBAk2&usp=drive_copy" />
            <Ep ep="S1-E13 Naruto - Hinanime" s="S1-E13" link="https://drive.google.com/open?id=1w1Q_OaN93ImUp-zSKGixARixrxI6l6bT&usp=drive_copy" />
            <Ep ep="S1-E14 Naruto - Hinanime" s="S1-E14" link="https://drive.google.com/open?id=17CcIpsRmDWT-HwwjlphsYNbv3lL7mh4c&usp=drive_copy" />
            <Ep ep="S1-E15 Naruto - Hinanime" s="S1-E15" link="https://drive.google.com/open?id=1xuqrvlVSxC28TlTIRs0aZLGBLqZ37vv7&usp=drive_copy" />
            <Ep ep="S1-E16 Naruto - Hinanime" s="S1-E16" link="https://drive.google.com/open?id=1fS1EIRal7yqtvTCk3MDD2LdcGWcRjCG0&usp=drive_copy" />
            <Ep ep="S1-E17 Naruto - Hinanime" s="S1-E17" link="https://drive.google.com/open?id=1IE7yG6zfMa7Ul3YA0qmExeUylA3uAHI-&usp=drive_copy" />
            <Ep ep="S1-E18 Naruto - Hinanime" s="S1-E18" link="https://drive.google.com/open?id=1fOE2Z0whdQg7NRuAYiEtXJX-JO4jaDx3&usp=drive_copy" />
            <Ep ep="S1-E19 Naruto - Hinanime" s="S1-E19" link="https://drive.google.com/open?id=1D6jVpvaTYrfG6FlMKcWkNZu7Mal_ws59&usp=drive_copy" />
            <Ep ep="S1-E20 Naruto - Hinanime" s="S1-E20" link="https://drive.google.com/open?id=1xXDbzShSKCTCtom8x-by8IrYUI9GsUZ6&usp=drive_copy" />
            <Ep ep="S1-E21 Naruto - Hinanime" s="S1-E21" link="https://drive.google.com/open?id=1WBQUx5sZaLAg2XjG1jWQzgai2zilCiZl&usp=drive_copy" />
            <Ep ep="S1-E22 Naruto - Hinanime" s="S1-E22" link="https://drive.google.com/open?id=19RvPb2nZFL2URi2XZKL49fn7SHXilTcP&usp=drive_copy" />
            <Ep ep="S1-E23 Naruto - Hinanime" s="S1-E23" link="https://drive.google.com/open?id=1vleKYIWvQVm8nkvfjv2hVPfJY_bSALcf&usp=drive_copy" />
            <Ep ep="S1-E24 Naruto - Hinanime" s="S1-E24" link="https://drive.google.com/open?id=1VjY1BiRckqQ2H2_2_vWCpslUrckmonzS&usp=drive_copy" />
            <Ep ep="S1-E25 Naruto - Hinanime" s="S1-E25" link="https://drive.google.com/open?id=1FPGIYsPby-0ndWbMRVNyhoFs5DFuoaHu&usp=drive_copy" />
            <Ep ep="S1-E26 Naruto - Hinanime" s="S1-E26" link="https://drive.google.com/open?id=1TpLcr4f0k3GYMTnglE6NvMh4CVcfYSq4&usp=drive_copy" />
            


            <Animeover />
            <Footer />
        </>
    )
}

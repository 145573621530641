import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";
export default function Whydoes() {
  return (
    <>
    <Main img="https://i.postimg.cc/DZDtRVs5/season-1-1.jpg" name="Why Does Nobody Remember Me In This World" s="1" e="12" />

      <Ep ep="S1-E1 Why Does Nobody Remember Me In This World" s="S1-E1" link="https://drive.google.com/open?id=1ScER5mXgUvwBF_ywutI5iTZsKk9iC-oe&usp=drive_copy" />
      <Ep ep="S1-E2 Why Does Nobody Remember Me In This World" s="S1-E2" link="https://drive.google.com/open?id=1bzRyik0--Q4RizWBUlr3V_pRsZpe6pHg&usp=drive_copy" />
      <Ep ep="S1-E3 Why Does Nobody Remember Me In This World" s="S1-E3" link="https://drive.google.com/open?id=1iOZ-oSQfNWFMZqsSu2GMNpj10j3ldzvU&usp=drive_copy" />
      <Ep ep="S1-E4 Why Does Nobody Remember Me In This World" s="S1-E4" link="https://drive.google.com/open?id=1EDQvjpuRZu9DZMZK6SE7o5pdzW2pd2Qp&usp=drive_copy"/>
      <Ep ep="S1-E5 Why Does Nobody Remember Me In This World" s="S1-E5" link="https://drive.google.com/open?id=1c_eZV6Oi-1pfm4e6VwcWDuou_mg-ka1D&usp=drive_copy"/>
       <Ep ep="S1-E6 Why Does Nobody Remember Me In This World" s="S1-E6" link="https://drive.google.com/file/d/1tv86M79_NJUeColfWlEWgpGB6FpLqSyU/view?usp=sharing"/>
      {/* <Ep ep="S1-E7 Why Does Nobody Remember Me In This World" s="S1-E7" link=""/>
      <Ep ep="S1-E8 Why Does Nobody Remember Me In This World" s="S1-E8" link=""/>
      <Ep ep="S1-E9 Why Does Nobody Remember Me In This World" s="S1-E9" link=""/>
      <Ep ep="S1-E10 Why Does Nobody Remember Me In This World" s="S1-E10" link=""/>
      <Ep ep="S1-E11 Why Does Nobody Remember Me In This World" s="S1-E11" link=""/>
      <Ep ep="S1-E12 Why Does Nobody Remember Me In This World" s="S1-E12" link=""/> */}
    <Animeover />
    <Footer />
  </>
  )
}

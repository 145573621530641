import React from 'react'

import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";

export default function Igotchat() {
  return (
<>
 <Main img="https://i.postimg.cc/tRNqxv1s/MV5-BZDBj-Zj-Q1-ZWQt-NWJl-MS00-MWFi-LWE0-NDAt-NDhi-Mzk0-MTZm-M2-Vi-Xk-Ey-Xk-Fqc-Gde-QXVy-Mzgx-ODM4-Nj-M-V1-FMjpg-UX1000.jpg" name=" Got a Cheat Skill in Another World" s="1" e="13" />

 <Ep ep="S1-E1 Got a Cheat Skill in Another World" s="S1-E1" link="https://drive.google.com/open?id=1oDh3G7OZgcSM83hdbJDmxudqC4YnZ9VM&usp=drive_copy"/>
<Ep ep="S1-E2 Got a Cheat Skill in Another World" s="S1-E2" link="https://drive.google.com/open?id=1T1VTEvn8SqB79k60ilzeulLOzMhQzkVB&usp=drive_copy"/>
<Ep ep="S1-E3 Got a Cheat Skill in Another World" s="S1-E3" link="https://drive.google.com/open?id=1iCSdgEinvensfKzPaQF4LekH14mYBaqU&usp=drive_copy"/>
<Ep ep="S1-E4 Got a Cheat Skill in Another World" s="S1-E4" link="https://drive.google.com/open?id=1ozT4ldkNdMBrGk2DJ5Dqbzq2mUdYWZs0&usp=drive_copy"/>
<Ep ep="S1-E5 Got a Cheat Skill in Another World" s="S1-E5" link="https://drive.google.com/open?id=1b-THIfEAtmyszfzYlkq-p05vVbT23PGR&usp=drive_copy"/>
<Ep ep="S1-E6 Got a Cheat Skill in Another World" s="S1-E6" link="https://drive.google.com/open?id=1_xkkUMcqRBeLen_LKw1uLSRUYq5FcQSN&usp=drive_copy"/>
<Ep ep="S1-E7 Got a Cheat Skill in Another World" s="S1-E7" link="https://drive.google.com/open?id=1kOMTuXiRD2NWXWRUupt_r0uZKbOatz1I&usp=drive_copy"/>
<Ep ep="S1-E8 Got a Cheat Skill in Another World" s="S1-E8" link="https://drive.google.com/open?id=1Go8V5Ib7Se7TYRMHwr57KHaG7FS0KDJ8&usp=drive_copy"/>
<Ep ep="S1-E9 Got a Cheat Skill in Another World" s="S1-E9" link="https://drive.google.com/open?id=1sYjixkjmwpxMsHs7ydaa3L7QDPtHabNy&usp=drive_copy"/>
<Ep ep="S1-E10 Got a Cheat Skill in Another World" s="S1-E10" link="https://drive.google.com/open?id=1jeEGYXUPm6RCXOf07kA67zRZH59k8QN2&usp=drive_copy"/>
<Ep ep="S1-E11 Got a Cheat Skill in Another World" s="S1-E11" link="https://drive.google.com/open?id=1IIxWnbNzCB3JmBTN9hYBxmAJ64Da1yk9&usp=drive_copy"/>
<Ep ep="S1-E12 Got a Cheat Skill in Another World" s="S1-E12" link="https://drive.google.com/open?id=1HHS2hgxh83SVnEGiNcQaDacfRv0yJpsT&usp=drive_copy"/>
<Ep ep="S1-E13 Got a Cheat Skill in Another World" s="S1-E13" link="https://drive.google.com/open?id=1FNrh9PZ4K7kaarJBiOZFJ6OEFy2IhzWF&usp=drive_copy"/>

 <Animeover/>
<Footer/>
</>
  )
}

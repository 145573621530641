import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";
export default function Towerofgod() {
  return (
    <>
      <Main img="https://i.postimg.cc/8Cm9kJvF/og-image.webp" name="Tower of God" s="2" e="13" />

      <Ep ep="S1-E1 Tower of God" s="S1-E1" link="https://drive.google.com/open?id=1ehbTBjsqFUaFaO4-2vJgLibgRRdhKDkh&usp=drive_copy" />
      <Ep ep="S1-E2 Tower of God" s="S1-E2" link="https://drive.google.com/open?id=1NzLk4W8jPx7hpVjk8NkCkieRKsNVZx7Z&usp=drive_copy" />
      <Ep ep="S1-E3 Tower of God" s="S1-E3" link="https://drive.google.com/open?id=1fBgTvC6EFOdAz1yCfelwjetiyYohmU8j&usp=drive_copy" />
      <Ep ep="S1-E4 Tower of God" s="S1-E4" link="https://drive.google.com/open?id=1VWVYyCx9cz0Dm3LvTECa91MI_mwv2IV1&usp=drive_copy"/>
      <Ep ep="S1-E5 Tower of God" s="S1-E5" link="https://drive.google.com/open?id=1Z5MZQ5RdXswsKbe6shOtPP0vLJMMCR4x&usp=drive_copy"/>
      <Ep ep="S1-E6 Tower of God" s="S1-E6" link="https://drive.google.com/open?id=1fIsyX-iTxwCIqjxSnUeBuDQU2z1e7cCC&usp=drive_copy"/>
      <Ep ep="S1-E7 Tower of God" s="S1-E7" link="https://drive.google.com/open?id=1h7_Jbhu72Qx0bqO3jo9oMXQReVmm0zV-&usp=drive_copy"/>
      <Ep ep="S1-E8 Tower of God" s="S1-E8" link="https://drive.google.com/open?id=1iJw1JY9lgAKc4zGdk73YKPpwnEIGD_aY&usp=drive_copy"/>
      {/*<Ep ep="S1-E9 Tower of God" s="S1-E9" link=""/>
      <Ep ep="S1-E10 Tower of God" s="S1-E10" link=""/>
      <Ep ep="S1-E11 Tower of God" s="S1-E11" link=""/>
      <Ep ep="S1-E12 Tower of God" s="S1-E12" link=""/> */}
      <Animeover />
      <Footer />
    </>
  )
}

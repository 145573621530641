// src/data.js

const data = [
  {
    id: 1,
    name: "/",
    link: "/",
  },
  {
    id: 2,
    name: "Assassintion Classroom season 1",
    link: "/Assassintion Classroom season 1",
  },

  {
    id: 3,
    name: "Alya Sometimes Hides Her Feelings in Russian",
    link: "/Alya Sometimes Hides Her Feelings in Russian",
  },

  {
    id: 4,
    name: "Assassination Classroom season 2",
    link: "/Assassination Classroom season 2",
  },

  {
    id: 5,
    name: "Bye Bye, Earth",
    link: "/Bye Bye, Earth",
  },

  {
    id: 6,
    name: "Days with My Stepsister Season 1 Hindi Dubbed",
    link: "/Days with My Stepsister Season 1 Hindi Dubbed",
  },

  {
    id: 7,
    name: "Dr. STONE Season 2",
    link: "/Dr. STONE Season 2",
  },

  {
    id: 8,
    name: "Fairy Tail: 100 Years Quest",
    link: "/Fairy Tail: 100 Years Quest",
  },

  {
    id: 9,
    name: "Haikyu!! Season 1 Hindi Dubbed",
    link: "/Haikyu!! Season 1 Hindi Dubbed",
  },

  {
    id: 10,
    name: "Kaiju No. 8 Season 1 Hindi Dubbed",
    link: "/Kaiju No. 8 Season 1 Hindi Dubbed",
  },

  {
    id: 11,
    name: "Log Horizon Season 1 Hindi Dubbed",
    link: "/Log Horizon Season 1 Hindi Dubbed",
  },

  {
    id: 12,
    name: "Makeine: Too Many Losing Heroines! Hindi Dubbed",
    link: "/Makeine: Too Many Losing Heroines! Hindi Dubbed",
  },

  {
    id: 13,
    name: "No Longer Allowed in Another World Season 1 Hindi Dubbed",
    link: "/No Longer Allowed in Another World Season 1 Hindi Dubbed",
  },

  {
    id: 14,
    name: "Quality Assurance in Another World Hindi Dubbed",
    link: "/Quality Assurance in Another World Hindi Dubbed",
  },

  {
    id: 15,
    name: "Solo Leveling Season 1 Hindi Dubbed",
    link: "/Solo Leveling Season 1 Hindi Dubbed",
  },

  {
    id: 16,
    name: "The Strongest Magician in the Demon Lord’s Army Was a Human Season 1 Hindi Dubbed",
    link: "/The Strongest Magician in the Demon Lord’s Army Was a Human Season 1 Hindi Dubbed",
  },

  {
    id: 17,
    name: "Tower of God season 2 Hindi Dubbed",
    link: "/Tower of God season 2 Hindi Dubbed",
  },

  // {
  //   id: 18,
  //   name: "Tower of God Season 2 Hindi Dubbed",
  //   link: "/Tower of God Season 2 Hindi Dubbed",
  // },

  {
    id: 19,
    name: "True Beauty Season 1 Hindi Dubbed",
    link: "/True Beauty Season 1 Hindi Dubbed",
  },

  {
    id: 20,
    name: "Twilight Out of Focus Season 1 Hindi Dubbed",
    link: "/Twilight Out of Focus Season 1 Hindi Dubbed",
  },

  {
    id: 21,
    name: "Viral Hit Season 1 Hindi Dubbed",
    link: "/Viral Hit Season 1 Hindi Dubbed",
  },

  {
    id: 22,
    name: "VTuber Legend: How I Went Viral After Forgetting to Turn Off My Stream Hindi Dubbed",
    link: "/VTuber Legend: How I Went Viral After Forgetting to Turn Off My Stream Hindi Dubbed",
  },

  {
    id: 23,
    name: "Why Does Nobody Remember Me In This World Hindi Dubbed",
    link: "/Why Does Nobody Remember Me In This World Hindi Dubbed",
  },

  {
    id: 24,
    name: "Wistoria: Wand and Sword Hindi Dubbed",
    link: "/Wistoria: Wand and Sword Hindi Dubbed",
  },

  {
    id: 25,
    name: "demon slayer season 2",
    link: "/demon slayer season 2",
  },

  {
    id: 26,
    name: "Horimiya: The Missing Pieces premiered",
    link: "/Horimiya: The Missing Pieces premiered",
  },

  {
    id: 27,
    name: "Jujutsu kaisen season 2 Hindi Dubbed",
    link: "/Jujutsu kaisen season 2 Hindi Dubbed",
  },

  {
    id: 27,
    name: "Jujutsu kaisen season 1 Hindi Dubbed",
    link: "/Jujutsu kaisen season 1 Hindi Dubbed",
  },

  {
    id: 28,
    name: "spy x family season 2 hindi dubbed",
    link: "/spy x family season 2 hindi dubbed",
  },

  {
    id: 29,
    name: "Mushoku Tensei Jobless Reincarnation",
    link: "/Mushoku Tensei Jobless Reincarnation",
  },

  {
    id: 30,
    name: "Demon Slayer season 3 hindi dubbed",
    link: "/Demon Slayer season 3 hindi dubbed",
  },

  {
    id: 31,
    name: "Demon Slayer season 4 hindi dubbed",
    link: "/Demon Slayer season 4 hindi dubbed",
  },

  {
    id: 32,
    name: "An Archdemon's Dilemma: How to Love Your Elf Bride Season 1",
    link: "/An Archdemon's Dilemma: How to Love Your Elf Bride Season 1",
  },

  {
    id: 34,
    name: "Mashle : Magic and Muscles Season 2",
    link: "/Mashle : Magic and Muscles Season 2",
  },

  {
    id: 35,
    name: "naruto season 1 hindi dubbed",
    link: "/naruto season 1 hindi dubbed",
  },

  {
    id: 36,
    name: "Wind Breaker season 1 hindi dubbed",
    link: "/Wind Breaker season 1 hindi dubbed",
  },

  {
    id: 37,
    name: "Ranking of Kings season 1",
    link: "/Ranking of Kings season 1",
  },

  {
    id: 38,
    name: "Akebi's Sailor Uniform season 1",
    link: "/Akebi's Sailor Uniform season 1",
  },      

  {
    id: 39,
    name: "Black Rock Shooter: Dawn Fall season 1",
    link: "/Black Rock Shooter: Dawn Fall season 1",
  },

  {
    id: 40,
    name: "one punch man season 1 hindi dubbed ",
    link: "/one punch man season 1 hindi dubbed ",
  },

  {
    id:41,
    name:"Chainsaw Man season 1 hindi dubbed",
    link: "/Chainsaw Man season 1 hindi dubbed",
  },

  {
    id:42,
    name:"I Got a Cheat Skill in Another World",
    link: "/I Got a Cheat Skill in Another World",
  },

  {
    id:43,
    name:"",
    link: "/",
  },
  
  {
    id:44,
    name:"",
    link: "/",
  },

  {
    id:45,
    name:"",
    link: "/",
  },

  {
    id:46,
    name:"",
    link: "/",
  },

  {
    id:47,
    name:"",
    link: "/",
  },

  {
    id:48,
    name:"",
    link: "/",
  },

  {
    id:49,
    name:"",
    link: "/",
  },

  {
    id:50,
    name:"",
    link: "/",
  },
  
  


 
];

export default data;

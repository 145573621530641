// src/SearchBar.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import data from "./data";

const SearchBar = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);

    if (value) {
      const filteredData = data
        .filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
        .slice(0, 5);
      setResults(filteredData);
    } else {
      setResults([]);
    }
  };

  return (
    <>
      <div className="mt-6 ">
        <div className="flex justify-center">
          <div class="h-20 w-112 flex justify-center items-center backdrop-blur rounded-t-lgdis border border-whitegood shadow shadow-black">
            <div class="bg-sky-800 h-8 rounded-l-md flex justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                class="w-8 h-7 fill-sky-500"
              >
                <path d="M 19 3 C 13.488281 3 9 7.488281 9 13 C 9 15.394531 9.839844 17.589844 11.25 19.3125 L 3.28125 27.28125 L 4.71875 28.71875 L 12.6875 20.75 C 14.410156 22.160156 16.605469 23 19 23 C 24.511719 23 29 18.511719 29 13 C 29 7.488281 24.511719 3 19 3 Z M 19 5 C 23.429688 5 27 8.570313 27 13 C 27 17.429688 23.429688 21 19 21 C 14.570313 21 11 17.429688 11 13 C 11 8.570313 14.570313 5 19 5 Z"></path>
              </svg>
            </div>
            <input
              type="text"
              className="w-96 pl-5 h-8 flex
              items-center border-none rounded-r-md backdrop-blur bg-black text-white
              pb-1"
              value={query}
              onChange={handleInputChange}
              placeholder="Search..."
            />
          </div>
        </div>
        <div className="flex justify-center">
          <div className=" w-112  backdrop-blur rounded-b-lg border border-whitegood shadow shadow-black ">
            <ul className="pl-36  ">
              {results.map((item) => (
                <li key={item.id} className="mb-2 mt-2 hover:text-white">
                  <Link to={item.link}>{item.name}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBar;

import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";
import Complete from "../Structure/Complete";

export default function Jjk() {
  return (
    <>
      <Main
        img="https://a.storyblok.com/f/178900/2000x3000/8863dbd7c8/jujutsu-kaisen-season-2-key-art.png/m/filters:quality(95)format(webp)"
        name="Jujutsu kaisen"
        s="2"
        e="23"
      />

      <Ep ep="S2-E1 Jujutsu kaisen " s="S2-E1" link="https://drive.google.com/open?id=1BCek4muhAkLYJl0ZMTTMtBQbKn1TFOGc&usp=drive_copy"/>
      <Ep ep="S2-E2 Jujutsu kaisen " s="S2-E2" link="https://drive.google.com/file/d/1oH-d45gf8Rrkqcp8XtWvRUhu5DVKZ0xM/view?usp=sharing"/>
      <Ep ep="S2-E3 Jujutsu kaisen " s="S2-E3" link="https://drive.google.com/open?id=1G3mU_x1i98iSMvJsqIujoaqLvO2Mi1Ij&usp=drive_copy"/>
      <Ep ep="S2-E4 Jujutsu kaisen " s="S2-E4" link="https://drive.google.com/open?id=1TA2mBXjQLyhVG1YDRLpU3f-gv3TL_GoK&usp=drive_copy"/>
      <Ep ep="S2-E5 Jujutsu kaisen " s="S2-E5" link="https://drive.google.com/open?id=1EmxtkQSQ3gD2CnV--7wBlImLpBuaWL9s&usp=drive_copy"/>
      <Ep ep="S2-E6 Jujutsu kaisen " s="S2-E6" link="https://drive.google.com/open?id=1nUJmvaj18g6n7XX_Ebrfb69owh5UF4LZ&usp=drive_copy"/>
      <Ep ep="S2-E7 Jujutsu kaisen " s="S2-E7" link="https://drive.google.com/open?id=1E8iYxbdPijN-7EsFEJXiRP7Dn7gij7-8&usp=drive_copy"/>
      <Ep ep="S2-E8 Jujutsu kaisen " s="S2-E8" link="https://drive.google.com/open?id=1zciuDA6pidntzgcGpSTf1fXrqPkwDMCr&usp=drive_copy"/>
      <Ep ep="S2-E9 Jujutsu kaisen " s="S2-E9" link="https://drive.google.com/open?id=1a0dG32IqWoMFM3NZwnvaFXC2nwt8hvWc&usp=drive_copy"/>
      <Ep ep="S2-E10 Jujutsu kaisen " s="S2-E10" link="https://drive.google.com/open?id=1xwjCpFpG6PTz5-z2pCVzRp1DfRec7tlQ&usp=drive_copy"/>
      <Ep ep="S2-E11 Jujutsu kaisen " s="S2-E11" link="https://drive.google.com/open?id=1ErnURIazvOXhJtwmU-iiRFxQADkj2YVq&usp=drive_copy"/>
      <Ep ep="S2-E12 Jujutsu kaisen " s="S2-E12" link="https://drive.google.com/open?id=1fe7Rkpu_YWhBrJarznh9RkMXhUjESrSj&usp=drive_copy"/>
      <Ep ep ="S2-E13 jujutsu kaisen" s="S2-E13" link="https://drive.google.com/open?id=1WLtuquhDgRWfiPGfNDTdHbrjMcVEFPhd&usp=drive_copy"/>
      <Ep ep ="S2-E14 jujutsu kaisen" s="S2-E14" link="https://drive.google.com/open?id=1L_JuvaN6vKCtw35cNzlTvXJQckAZsmeB&usp=drive_copy"/>
      <Ep ep ="S2-E15 jujutsu kaisen" s="S2-E15" link="https://drive.google.com/open?id=1Oo2Gniu9_K8oTHcawK9-280rojjGtOaK&usp=drive_copy"/>
      <Ep ep ="S2-E16 jujutsu kaisen" s="S2-E16" link="https://drive.google.com/open?id=1xVEGU6cCyaWSNwT3b9Sumz9LxIG_2iKu&usp=drive_copy"/>
      <Ep ep ="S2-E17 jujutsu kaisen" s="S2-E17" link="https://drive.google.com/open?id=1LLQlN-D4U7HIU2_cYJ02fVqlIlZVUUzu&usp=drive_copy"/>
      <Ep ep ="S2-E18 jujutsu kaisen" s="S2-E18" link="https://drive.google.com/open?id=1X8JBVoPHRI-wuzWo1NBGbH2OUcSoHaoh&usp=drive_copy"/>
      <Ep ep ="S2-E19 jujutsu kaisen" s="S2-E19" link="https://drive.google.com/open?id=1jSNrJsz93QVnphBpYplErOr0K4_hq4UK&usp=drive_copy"/>
      <Ep ep ="S2-E20 jujutsu kaisen" s="S2-E20" link="https://drive.google.com/open?id=1Ki9b87A9F3Dur-8R-fVXscSK2_x8OyPA&usp=drive_copy"/>
      <Ep ep ="S2-E21 jujutsu kaisen" s="S2-E21" link="https://drive.google.com/open?id=1ufOtJPo-9bxZjWquKGep2_bXjH5gJmRp&usp=drive_copy"/>
      <Ep ep ="S2-E22 jujutsu kaisen" s="S2-E22" link="https://drive.google.com/open?id=1grZQkaWjBaMVxe1IXkKEdgI6KEIPFUyM&usp=drive_copy"/>
      <Ep ep ="S2-E23 jujutsu kaisen" s="S2-E23" link="https://drive.google.com/open?id=1CZjxLM0Ac2d8JmSCGvPtrM0gCHsvqHo7&usp=drive_copy"/>
     <Complete/>
      <Footer />
    </>
  );
}

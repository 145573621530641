import React from 'react'

import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";


export default function Alya() {
  return (
    <>
    
    <Main img="https://i.postimg.cc/9FmvSM5J/MV5-BNjg0-YTVj-NTIt-ZTI1-ZC00-MDZl-LTll-Yj-Qt-MDY0-ODMx-NDdl-Yj-Y3-Xk-Ey-Xk-Fqc-Gc-V1-FMjpg-UX1000.jpg" name="Alya Sometimes Hides Her Feelings in Russian Season 1" s="1" e="12" />

      <Ep ep="S1-E1 Alya Sometimes Hides Her Feelings in Russian Season 1" s="S1-E1" link="https://drive.google.com/open?id=1W-i3YoBRyPNUOBAtvSe-l_hwZFUp9Py-&usp=drive_copy" />
      <Ep ep="S1-E2 Alya Sometimes Hides Her Feelings in Russian Season 1" s="S1-E2" link="https://drive.google.com/open?id=1JbK1xrGQ1BZ61K3Cmo1JnsEsbAMvc-m3&usp=drive_copy" />
      <Ep ep="S1-E3 Alya Sometimes Hides Her Feelings in Russian Season 1" s="S1-E3" link="https://drive.google.com/open?id=1QH9TqWOJ1kF0XQF6ipN68ql2Ev_JA0Zs&usp=drive_copy" />
      <Ep ep="S1-E4 Alya Sometimes Hides Her Feelings in Russian Season 1" s="S1-E4" link="https://drive.google.com/open?id=1i1mxptc6TC8v7ZT6IZaikleSf7Bl0b-7&usp=drive_copy"/>
      <Ep ep="S1-E5 Alya Sometimes Hides Her Feelings in Russian Season 1" s="S1-E5" link="https://drive.google.com/open?id=19af8vNhCIsoR-5Tn9FzC5sRh5Db431hE&usp=drive_copy"/>
      <Ep ep="S1-E6 Alya Sometimes Hides Her Feelings in Russian Season 1" s="S1-E6" link="https://drive.google.com/open?id=1Yq7H8mw8e0xxEA5rOnvE1kBXUlh5rCp-&usp=drive_copy"/>
      <Ep ep="S1-E7 Alya Sometimes Hides Her Feelings in Russian Season 1" s="S1-E7" link="https://drive.google.com/open?id=1-5FUtNaBGARrSVzKDzSOKj_qk9Jhh6Me&usp=drive_copy"/>
      <Ep ep="S1-E8 Alya Sometimes Hides Her Feelings in Russian Season 1" s="S1-E8" link="https://drive.google.com/open?id=1gSQABgFBgXaST430lcp3LE6mv3zN1Ihg&usp=drive_copy"/>
      {/* <Ep ep="S1-E9 Alya Sometimes Hides Her Feelings in Russian Season 1" s="S1-E9" link=""/>
      <Ep ep="S1-E10 Alya Sometimes Hides Her Feelings in Russian Season 1" s="S1-E10" link=""/>
      <Ep ep="S1-E11 Alya Sometimes Hides Her Feelings in Russian Season 1" s="S1-E11" link=""/>
      <Ep ep="S1-E12 Alya Sometimes Hides Her Feelings in Russian Season 1" s="S1-E12" link=""/> */}
    <Animeover />
    <Footer />
  </>
  )
}

import React from 'react'

import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";

export default function Musoko() {
  return (
    <>
    <Main img="https://i.postimg.cc/DyfSpxxH/image.jpg" name="Mushoku Tensei" s="2" e="12" />
    <Ep ep="S1-E0 Mushoku Tensei Jobless Reincarnation" s="S1-E0" link="https://drive.google.com/open?id=1XsHiUAaevqcjrHZFqgBjgZUwcBRa5G2e&usp=drive_copy" />
    <Ep ep="S1-E1 Mushoku Tensei Jobless Reincarnation" s="S1-E1" link="https://drive.google.com/open?id=1tbAypHLVNOJnR9qg9yPmsBvvH3kCPq4j&usp=drive_copy" />
<Ep ep="S1-E2 Mushoku Tensei Jobless Reincarnation" s="S1-E2" link="https://drive.google.com/open?id=1pwG2aVt3JRsWbeTcvR-ygDt0JQQ9_yIR&usp=drive_copy" />
<Ep ep="S1-E3 Mushoku Tensei Jobless Reincarnation" s="S1-E3" link="https://drive.google.com/open?id=1EuX8t4psrnXkkxYnwsYKfSZmLxOr3v9F&usp=drive_copy" />
<Ep ep="S1-E4 Mushoku Tensei Jobless Reincarnation" s="S1-E4" link="https://drive.google.com/open?id=1JnW2ATbsJ7i-nA8hl8jwZby8yw4EG7yO&usp=drive_copy" />
<Ep ep="S1-E5 Mushoku Tensei Jobless Reincarnation" s="S1-E5" link="https://drive.google.com/open?id=14NY-Uv--OQSEgXQAEa3p9c6s-q3q4KxI&usp=drive_copy" />
<Ep ep="S1-E6 Mushoku Tensei Jobless Reincarnation" s="S1-E6" link="https://drive.google.com/open?id=1r86H1sBQnQAudaeB9neFDelOyBPhPOCn&usp=drive_copy" />
<Ep ep="S1-E7 Mushoku Tensei Jobless Reincarnation" s="S1-E7" link="https://drive.google.com/open?id=1_7sL_okd21j5KD1gNsKOFTbsivGHSwI9&usp=drive_copy" />
<Ep ep="S1-E8 Mushoku Tensei Jobless Reincarnation" s="S1-E8" link="https://drive.google.com/open?id=1bAqvePZ9HFOhp50todhSp6CFa-HFtf2u&usp=drive_copy" />
<Ep ep="S1-E9 Mushoku Tensei Jobless Reincarnation" s="S1-E9" link="https://drive.google.com/open?id=1zIpkQY86eZQTqrxMqwCmlmeolXeCOKOQ&usp=drive_copy" />
<Ep ep="S1-E10 Mushoku Tensei Jobless Reincarnation" s="S1-E10" link="https://drive.google.com/open?id=1OVaKWK1osQfMTWJH047-B7wN0MYspHuk&usp=drive_copy" />
<Ep ep="S1-E11 Mushoku Tensei Jobless Reincarnation" s="S1-E11" link="https://drive.google.com/open?id=1aODNz8vzEfR98poDTdEEBICkk4D6aqui&usp=drive_copy" />
<Ep ep="S1-E12 Mushoku Tensei Jobless Reincarnation" s="S1-E12" link="https://drive.google.com/open?id=1xhBBcyyzPs36BTSB43Nbs1q76CdD851v&usp=drive_copy" />
  <Animeover/>
  <Footer/>
    </>
  )
}

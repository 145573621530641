import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";
export default function Wistoria() {
  return (
    <>
    <Main img="https://i.postimg.cc/1tHQd7Bj/5n0-IZQA9-Mi-Xn5-JBKZibt-Nhh-FSAZ.jpg" name="Wistoria: Wand and Sword" s="1" e="12" />

      <Ep ep="S1-E1 Wistoria: Wand and Sword" s="S1-E1" link="https://drive.google.com/open?id=1tXgFxXk9_rFJS60tg1XRVKh5eMPu-HA4&usp=drive_copy" />
      <Ep ep="S1-E2 Wistoria: Wand and Sword" s="S1-E2" link="https://drive.google.com/open?id=1NgUXLVnFryH1kJa9hgLYHga4MqcbczWK&usp=drive_copy" />
      <Ep ep="S1-E3 Wistoria: Wand and Sword" s="S1-E3" link="https://drive.google.com/open?id=1eg3ZZGS9kdOVLqixAEsIzCh1quWy1BLz&usp=drive_copy" />
      <Ep ep="S1-E4 Wistoria: Wand and Sword" s="S1-E4" link="https://drive.google.com/open?id=1Fb88O9tmNyO3UqmIFEGRy2DRHG-uyYca&usp=drive_copy"/>
      <Ep ep="S1-E5 Wistoria: Wand and Sword" s="S1-E5" link="https://drive.google.com/open?id=1rbAsgKlYvOb-CyU6GDlRh-3u5AwmYgph&usp=drive_copy"/>
      <Ep ep="S1-E6 Wistoria: Wand and Sword" s="S1-E6" link="https://drive.google.com/open?id=14MJV6bSa7-de6u0e5vwA2tjlkvZmQ8pG&usp=drive_copy"/>
      <Ep ep="S1-E7 Wistoria: Wand and Sword" s="S1-E7" link="https://drive.google.com/open?id=1Elc7n6ehQIslE33t01HHWd1kaSrXn26S&usp=drive_copy"/>
      {/* <Ep ep="S1-E8 Wistoria: Wand and Sword" s="S1-E8" link=""/>
      <Ep ep="S1-E9 Wistoria: Wand and Sword" s="S1-E9" link=""/>
      <Ep ep="S1-E10 Wistoria: Wand and Sword" s="S1-E10" link=""/>
      <Ep ep="S1-E11 Wistoria: Wand and Sword" s="S1-E11" link=""/>
      <Ep ep="S1-E12 Wistoria: Wand and Sword" s="S1-E12" link=""/> */}
    <Animeover />
    <Footer />
  </>
  )
}

import React from "react";
import "./About.css";

import Footer from "./Footer";
export default function About() {
  return (
    <>
      <div className="flex justify-center -mt-7">
        <div className="h-100 w-100 bg-whitelite mt-16 backdrop-blur rounded-lg border border-whitegood shadow shadow-black max-md:w-96 max-md:h-112">
          <h1 className="text-3xl text-center pt-5 pb-5 font-bold">About Hinanime</h1>
          <div className="pl-5 text-lg">
            <h2 className="text-center pb-5">Hinanime is committed to providing anime fans with a premium experience. Here's what makes us unique:</h2>
            <ul>
              <li className="pb-3"><b>Safe:</b> Your security is our priority, ensuring a safe and secure environment for enjoying anime without any risks.</li>
              <li className="pb-3"><b>Ads-Free:</b> Enjoy a seamless, ad-free experience, so you can watch your favorite shows without interruptions.</li>
              <li className="pb-3"><b>All-Time Updates:</b> Stay current with the latest anime releases, as we consistently update our library to keep you entertained.</li>
             
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

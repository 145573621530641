import React from "react";
import { Link } from "react-router-dom";
import "./Ban.css";

export default function Ban(props) {
  return (
    <>
      <div className="main-glass">
        <div className="main-ban">
          <Link to={props.link}>
            <img src={props.ban} alt="Anime images of roco toons " />
          </Link>
        </div>
      </div>
    </>
  );
}

import React from 'react'

import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";

export default function Akedi() {
  return (
    <>
 <Main img="https://i.postimg.cc/FRCNzxQC/MV5-BMWRk-NWE1-M2-Ut-ZTAz-OC00-ZTI0-LTll-Mzkt-ZTA0-ZTRi-Mjdj-Nm-Y5-Xk-Ey-Xk-Fqc-Gde-QXVy-Mzgx-ODM4-Nj-M-V1-FMjpg-UX1000.jpg" name="Akebi's Sailor Uniform" s="1" e="12" />
 
 <Ep ep="S1-E1 Akebi's Sailor Uniform" s="S1-E1" link="https://drive.google.com/open?id=1Mf53SC06wZg5Dcoz01qe098b91POmXFf&usp=drive_copy"/>
<Ep ep="S1-E2 Akebi's Sailor Uniform" s="S1-E2" link="https://drive.google.com/open?id=1LJV8Jra3zgq3TRcvTDdj8JgvuGxrAywB&usp=drive_copy"/>
<Ep ep="S1-E3 Akebi's Sailor Uniform" s="S1-E3" link="https://drive.google.com/open?id=1g8z9iQ3n0zooEOP-24o-huY88nNvhxEY&usp=drive_copy"/>
<Ep ep="S1-E4 Akebi's Sailor Uniform" s="S1-E4" link="https://drive.google.com/open?id=1QMnJAbQqgfagoufXqANg_uFC9_rkoW3b&usp=drive_copy"/>
<Ep ep="S1-E5 Akebi's Sailor Uniform" s="S1-E5" link="https://drive.google.com/open?id=11cSymC4M9ZCUvMfBs8b7cyGjQm1Tdk5U&usp=drive_copy"/>
<Ep ep="S1-E6 Akebi's Sailor Uniform" s="S1-E6" link="https://drive.google.com/open?id=14yi1N6sT7V3-yCTj5ChL9zz4rqkKndIN&usp=drive_copy"/>
<Ep ep="S1-E7 Akebi's Sailor Uniform" s="S1-E7" link="https://drive.google.com/open?id=1eiraOvg6t066fa1EBGIqDwZ9et50YPCA&usp=drive_copy"/>
<Ep ep="S1-E8 Akebi's Sailor Uniform" s="S1-E8" link="https://drive.google.com/open?id=1wR1OjuxqcWPa3P8lhlEC6XjcK-QXJMKU&usp=drive_copy"/>
<Ep ep="S1-E9 Akebi's Sailor Uniform" s="S1-E9" link="https://drive.google.com/open?id=1MFePvXSgH-Eu6_7txTsfuA69OCIdsm69&usp=drive_copy"/>
<Ep ep="S1-E10 Akebi's Sailor Uniform" s="S1-E10" link="https://drive.google.com/open?id=1mIdsf1Qy8uALgVhkTvRgXEsys1h3T_Uy&usp=drive_copy"/>
<Ep ep="S1-E11 Akebi's Sailor Uniform" s="S1-E11" link="https://drive.google.com/open?id=1rpP1Ud4JERBbl0qvZMmPYd-TXdNmPLPR&usp=drive_copy"/>
<Ep ep="S1-E12 Akebi's Sailor Uniform" s="S1-E12" link="https://drive.google.com/open?id=1-T6v_AzB7dPgIPxR-UTCe_I9hO3EEd6a&usp=drive_copy"/>

 <Animeover/>
<Footer/>
</>
  )
}

import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";

import Footer from "../components/Footer";
import Complete from "../Structure/Complete";
export default function Drstone() {
  return (
    <>
      <Main img="https://i.postimg.cc/8CT4kyNG/OIP-2.jpg" name="Dr. STONE " s="2" e="24" />

      <Ep ep="S1-E1 Dr. STONE " s="S1-E1" link="https://drive.google.com/open?id=1BF0IXGwSaFC06Do1jeGASGp-VULnscHj&usp=drive_copy" />
      <Ep ep="S1-E2 Dr. STONE " s="S1-E2" link="https://drive.google.com/open?id=1VtCQR_89Cm2fOvP5-39uoDuVFIKxlc3w&usp=drive_copy" />
      <Ep ep="S1-E3 Dr. STONE " s="S1-E3" link="https://drive.google.com/open?id=1gfj3HRscpQ2_IUFEhrpIbdnlzzqb818s&usp=drive_copy" />
      <Ep ep="S1-E4 Dr. STONE " s="S1-E4" link="https://drive.google.com/open?id=1-OsvqbKFLyYspBaf6gwnMn1oHHJkzBuD&usp=drive_copy"/>
      <Ep ep="S1-E5 Dr. STONE " s="S1-E5" link="https://drive.google.com/open?id=1MAI5A-1bkV_dR04xSV1tuQJpXi_EukV0&usp=drive_copy"/>
      <Ep ep="S1-E6 Dr. STONE " s="S1-E6" link="https://drive.google.com/open?id=18jgqnv04x4NXqaTtHnV5WKBTA6aPWN53&usp=drive_copy"/>
      <Ep ep="S1-E7 Dr. STONE " s="S1-E7" link="https://drive.google.com/open?id=1evhNYUfnpNq7Id8yZSo49_x1uEq5BgMi&usp=drive_copy"/>
      <Ep ep="S1-E8 Dr. STONE " s="S1-E8" link="https://drive.google.com/open?id=19ZNHVQYQE8iRj_KYqeJafYI_12BhCCYs&usp=drive_copy"/>
      <Ep ep="S1-E9 Dr. STONE " s="S1-E9" link="https://drive.google.com/open?id=1knRrxHrHSfw4m3ceSW6qPQuJk5Z3pXie&usp=drive_copy"/>
      <Ep ep="S1-E10 Dr. STONE " s="S1-E10" link="https://drive.google.com/open?id=1bGiulNsg_B7VZGxRleGVOJpj3HAQx-mD&usp=drive_copy"/>
      <Ep ep="S1-E11 Dr. STONE " s="S1-E11" link="https://drive.google.com/open?id=1eNYh8VDPDsyN8v5bTPEDwBwKszas7OUd&usp=drive_copy"/>
      <Ep ep="S1-E12 Dr. STONE " s="S1-E12" link="https://drive.google.com/open?id=1eKn_8pnBUqxP127UxTsZe4XJ1HdbuVBS&usp=drive_copy"/>
      <Ep ep="S1-E13 Dr. STONE " s="S1-E13" link="https://drive.google.com/open?id=1VJh4SxyLWBgCtag5l5Wzx4jBddgBEoIJ&usp=drive_copy"/>
      <Ep ep="S1-E14 Dr. STONE " s="S1-E14" link="https://drive.google.com/open?id=14B8QwZHUB_6L8_JZDsUOXxr_CCa6kQco&usp=drive_copy"/>
      <Ep ep="S1-E15 Dr. STONE " s="S1-E15" link="https://drive.google.com/open?id=1BYXCIK08AnNRS52SuYVQEe3mVN8M5H7W&usp=drive_copy"/>
      <Ep ep="S1-E16 Dr. STONE " s="S1-E16" link="https://drive.google.com/open?id=1801oXZRwdO2w8p7eMirkqv57X982AXE7&usp=drive_copy"/>
      <Ep ep="S1-E17 Dr. STONE " s="S1-E17" link="https://drive.google.com/open?id=1UI3BoKJ0Bw49j1JQlmBp3LhukSxS4NLh&usp=drive_copy"/>
      <Ep ep="S1-E18 Dr. STONE " s="S1-E18" link="https://drive.google.com/open?id=1d-C8Ca4NCb4euq5WW8wgWe-1PPADKhcI&usp=drive_copy"/>
      <Ep ep="S1-E19 Dr. STONE " s="S1-E19" link="https://drive.google.com/open?id=1e-cu8ehV9d53mfvxStKnVNb4iMZvzEks&usp=drive_copy"/>
      <Ep ep="S1-E20 Dr. STONE " s="S1-E20" link="https://drive.google.com/open?id=1LTSAQaHHA3cQVArZqZtoFCQ0qFof6LQm&usp=drive_copy"/>
      <Ep ep="S1-E21 Dr. STONE " s="S1-E21" link="https://drive.google.com/open?id=1_gKUl9evR06NzqlzdAqovxqq0MDA8Ruc&usp=drive_copy"/>
      <Ep ep="S1-E22 Dr. STONE " s="S1-E22" link="https://drive.google.com/open?id=1WZQYT33AkLScPORWgqmJ6TE2q8CeaNKM&usp=drive_copy"/>
      <Ep ep="S1-E23 Dr. STONE " s="S1-E23" link="https://drive.google.com/open?id=11vaDAdtx6gKcyHRQY2GPShSbLXw6PM5L&usp=drive_copy"/>
      <Ep ep="S1-E24 Dr. STONE " s="S1-E24" link="https://drive.google.com/open?id=1L3zMn72FXIjDcjFLui5R0fwN7-9YkRBL&usp=drive_copy"/>
      

      <Complete/>
      <Footer />
    </>
  )
}

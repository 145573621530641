import React from 'react'
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";

export default function Horim() {
  return (
    <>
    <Main img="https://i.postimg.cc/zX257gjr/a-EJVHb-Xa-C94y6th-J3n-A6l-BBn-Hjs.jpg" name="Horimiya: The Missing Pieces premiered" s="1" e="12" />

      <Ep ep="S1-E1 Horimiya: The Missing Pieces premiered" s="S1-E1" link="https://drive.google.com/open?id=1mmzBzxWqTUBhIC5Ag3TZubzoWjWdLfOh&usp=drive_copy" />
      <Ep ep="S1-E2 Horimiya: The Missing Pieces premiered" s="S1-E2" link="https://drive.google.com/open?id=1AmFQ3oCfQ5MPM7TT4vjInlnnm3GEsSr8&usp=drive_copy" />
      <Ep ep="S1-E3 Horimiya: The Missing Pieces premiered" s="S1-E3" link="https://drive.google.com/open?id=1bq9JMgSS1_6XXUSnhe1CqDc6KjJdZcLG&usp=drive_copy" />
      <Ep ep="S1-E4 Horimiya: The Missing Pieces premiered" s="S1-E4" link="https://drive.google.com/open?id=1DVsQSgEvk3wtgLJmmrDZP1AhVYCJDhJi&usp=drive_copy"/>
      <Ep ep="S1-E5 Horimiya: The Missing Pieces premiered" s="S1-E5" link="https://drive.google.com/open?id=1at07VrS4izJNSSNl-tZ6bO1LhuZFcmbt&usp=drive_copy"/>
      <Ep ep="S1-E6 Horimiya: The Missing Pieces premiered" s="S1-E6" link="https://drive.google.com/open?id=1YU9--ey63w8T6ihcKUidKqewN1exQbXM&usp=drive_copy"/>
      <Ep ep="S1-E7 Horimiya: The Missing Pieces premiered" s="S1-E7" link="https://drive.google.com/open?id=1RkjdZ4bbOji3Rb465Y1sthvxu8Mo5ggg&usp=drive_copy"/>
      <Ep ep="S1-E8 Horimiya: The Missing Pieces premiered" s="S1-E8" link="https://drive.google.com/open?id=1ZYCWMcl5st1JFdK3HybA256B2c5bBuIL&usp=drive_copy"/>
      <Ep ep="S1-E9 Horimiya: The Missing Pieces premiered" s="S1-E9" link="https://drive.google.com/open?id=1kzzAJBYwYLQ8gd7aso0VXTS2JHHz46y6&usp=drive_copy"/>
      <Ep ep="S1-E10 Horimiya: The Missing Pieces premiered" s="S1-E10" link="https://drive.google.com/open?id=1VAPIagWk3so28AzRw_oDChGnEgsus4Vo&usp=drive_copy"/>
      <Ep ep="S1-E11 Horimiya: The Missing Pieces premiered" s="S1-E11" link="https://drive.google.com/open?id=134LSPocFUcB1tq8ehfwbhycHAtCxR8l4&usp=drive_copy"/>
      <Ep ep="S1-E12 Horimiya: The Missing Pieces premiered" s="S1-E12" link="https://drive.google.com/open?id=13dQKWHVt_TaqW5SzkmQe2HTF99EalONS&usp=drive_copy"/>
      <Ep ep="S1-E13 Horimiya: The Missing Pieces premiered" s="S1-E12" link="https://drive.google.com/file/d/1yUldJKkIZKWjT0qp8SSAwMVHuzZgLTQG/view?usp=drive_link" />
      <Animeover /> 
      <Footer />
      </>
  )
}

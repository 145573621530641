import React from 'react'

import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";

export default function Anarchdemon() {
  return (
   <>
    <Main img="https://i.postimg.cc/GmydXYn6/MV5-BOWVi-Nm-M5-Yj-Yt-NWJl-Yi00-MDk4-LWE1-MTIt-MTg2-N2-Q4-ODRk-YTU2-Xk-Ey-Xk-Fqc-Gde-QXVy-Mzgx-ODM4-Nj-M-V1.jpg" name="An Archdemon's Dilemma How to Love Your Elf Bride" s="1" e="12" />

    <Ep ep="S1-E1 An Archdemon's Dilemma: How to Love Your Elf Bride" s="S1-E1" link="https://drive.google.com/open?id=1o7vwPD6ugHugtbgxBh43VYptAqiJaD8P&usp=drive_copy" />
<Ep ep="S1-E2 An Archdemon's Dilemma: How to Love Your Elf Bride" s="S1-E2" link="https://drive.google.com/open?id=1T6g1sKN0BIWCevMCNUv8sTaVH_7DpErV&usp=drive_copy" />
<Ep ep="S1-E3 An Archdemon's Dilemma: How to Love Your Elf Bride" s="S1-E3" link="https://drive.google.com/open?id=15gPQIpmsIY0rz1wB8LepxLypms-rSZIO&usp=drive_copy" />
<Ep ep="S1-E4 An Archdemon's Dilemma: How to Love Your Elf Bride" s="S1-E4" link="https://drive.google.com/open?id=1fC3qVSi0N2BP7hEJ_USs6Owr-hmXKNmU&usp=drive_copy" />
<Ep ep="S1-E5 An Archdemon's Dilemma: How to Love Your Elf Bride" s="S1-E5" link="https://drive.google.com/open?id=1gWo37STvSOF0Om1dUXVdZTT0MSojpDxb&usp=drive_copy" />
<Ep ep="S1-E6 An Archdemon's Dilemma: How to Love Your Elf Bride" s="S1-E6" link="https://drive.google.com/open?id=1D-iKNi2zGIwOYAWU27YIu-XSan9qLNXI&usp=drive_copy" />
<Ep ep="S1-E7 An Archdemon's Dilemma: How to Love Your Elf Bride" s="S1-E7" link="https://drive.google.com/open?id=1N04UtAGAJ7mLkwYWKQsVSt-d1z2CCiLu&usp=drive_copy" />
<Ep ep="S1-E8 An Archdemon's Dilemma: How to Love Your Elf Bride" s="S1-E8" link="https://drive.google.com/open?id=1tNl_7xqgcC2KWInlk9puRmLXrvzSMhDI&usp=drive_copy" />
<Ep ep="S1-E9 An Archdemon's Dilemma: How to Love Your Elf Bride" s="S1-E9" link="https://drive.google.com/open?id=1OUdXp1vAeWnopW3NLTJPNkkPn12N2Ui_&usp=drive_copy" />
<Ep ep="S1-E10 An Archdemon's Dilemma: How to Love Your Elf Bride" s="S1-E10" link="https://drive.google.com/open?id=1WmiNOi7KUhfBQrrneLcE2WWWBqgAcior&usp=drive_copy" />
<Ep ep="S1-E11 An Archdemon's Dilemma: How to Love Your Elf Bride" s="S1-E11" link="https://drive.google.com/open?id=1ERT-DKBfF5KgBuSY6fRtaThnsHbzLVVL&usp=drive_copy" />
<Ep ep="S1-E12 An Archdemon's Dilemma: How to Love Your Elf Bride" s="S1-E12" link="https://drive.google.com/open?id=1pPpZ_Pal2w0XKr80ckwgHhzQDYvkockE&usp=drive_copy" />
  
  <Animeover />
  <Footer />
   </>
  )
}

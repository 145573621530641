import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";
export default function Twilinghtout() {
  return (
    <>
      <Main img="https://i.postimg.cc/dtV554Wf/MV5-BOTZh-ZWQ3-ZGUt-Zj-M2-NC00-OTVi-LWI3-Mz-It-OWJk-Nz-Fk-Y2-Zh-Mzcw-Xk-Ey-Xk-Fqc-Gc-V1-FMjpg-UX1000.jpg" name="Twilight Out of Focus" s="1" e="12" />

      <Ep ep="S1-E1 Twilight Out of Focus" s="S1-E1" link="https://drive.google.com/open?id=1rAqrNyceZoSAX1sC_mV-mQIECzUP4sGF&usp=drive_copy" />
      <Ep ep="S1-E2 Twilight Out of Focus" s="S1-E2" link="https://drive.google.com/open?id=1VU3Oa0dYvz29N2o7zb14A5d17q5uG0aq&usp=drive_copy" />
      <Ep ep="S1-E3 Twilight Out of Focus" s="S1-E3" link="https://drive.google.com/open?id=1Voe5on46XMfMsHz7xz4mrjLYr1EiS3NU&usp=drive_copy" />
      <Ep ep="S1-E4 Twilight Out of Focus" s="S1-E4" link="https://drive.google.com/open?id=1jrGRDhwJDQuQB6WQpboG37uq3mBdDxxf&usp=drive_copy"/>
      <Ep ep="S1-E5 Twilight Out of Focus" s="S1-E5" link="https://drive.google.com/open?id=1-SoslRFD-zZQljXkuo_r4eGGxNk2H4-Z&usp=drive_copy"/>
      <Ep ep="S1-E6 Twilight Out of Focus" s="S1-E6" link="https://drive.google.com/open?id=1bSPRrshOnoj1I7iYamc3H7x9QuAyzVQ9&usp=drive_copy"/>
      <Ep ep="S1-E7 Twilight Out of Focus" s="S1-E7" link="https://drive.google.com/open?id=195XfUl9hAP0vX54k5xdjjx_Vm1yJznas&usp=drive_copy"/>
       <Ep ep="S1-E8 Twilight Out of Focus" s="S1-E8" link="https://drive.google.com/open?id=1rILAKK7uzVy-oiAUanEhDLf5VkRkF70W&usp=drive_copy"/>
      {/* <Ep ep="S1-E9 Twilight Out of Focus" s="S1-E9" link=""/>
      <Ep ep="S1-E10 Twilight Out of Focus" s="S1-E10" link=""/>
      <Ep ep="S1-E11 Twilight Out of Focus" s="S1-E11" link=""/>
      <Ep ep="S1-E12 Twilight Out of Focus" s="S1-E12" link=""/> */}
      <Animeover />
      <Footer />
    </>
  )
}

import React from "react";
import "./Ep.css";

export default function Ep(props) {
  return (
    <>
      <div className="ep-titles">
        <p>{props.ep} - Hin-Anime</p> 
        <a
          href={props.link}
          target="_blank"
          rel="noreferrer"
          download={props.ep}
        >
          <button>Download {props.s}</button>
        </a>
      </div>
    </>
  );
}

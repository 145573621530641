import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";
export default function Loghorizon() {
  return (
    <>
    <Main img="https://i.postimg.cc/SRMJbrMb/x-LGd-Ru-WCn2-Al-By-L5-Y9-OLB3-Zw-Itg.jpg" name="Log Horizon " s="1" e="25" />

    <Ep ep="S1-E1 Log Horizon " s="S1-E1" link="https://drive.google.com/open?id=1iVypSxn21b_qhqs8VHRE32lqp4jqkKqb&usp=drive_copy" />
      <Ep ep="S1-E2 Log Horizon " s="S1-E2" link="https://drive.google.com/open?id=1_DQ9oi2vM0HEx0rXI3ua5gDg2Er3ph0h&usp=drive_copy" />
      <Ep ep="S1-E3 Log Horizon " s="S1-E3" link="https://drive.google.com/open?id=1oiuMvlvgjolNpjo-ugOvB-xKbLCj5eFq&usp=drive_copy" />
      <Ep ep="S1-E4 Log Horizon " s="S1-E4" link="https://drive.google.com/open?id=1e_nFM6TdeUwiSwNnEoM14zD79Mvrk1Pe&usp=drive_copy"/>
      <Ep ep="S1-E5 Log Horizon " s="S1-E5" link="https://drive.google.com/open?id=1kNHrgE0_ZrswvimGsrTGGqO2uApROvk8&usp=drive_copy"/>
      <Ep ep="S1-E6 Log Horizon " s="S1-E6" link="https://drive.google.com/open?id=1Vk0nnjKger8uaCsNLLPfDsRDMa6wkb6t&usp=drive_copy"/>
      <Ep ep="S1-E7 Log Horizon " s="S1-E7" link="https://drive.google.com/open?id=19nolzqw2uedEyTmrHUmBEvMWzc4PLGBa&usp=drive_copy"/>
      <Ep ep="S1-E8 Log Horizon " s="S1-E8" link="https://drive.google.com/open?id=1Yj6vwXaEfS94V-vJki75g1U40RXiJT8B&usp=drive_copy"/>
      <Ep ep="S1-E9 Log Horizon " s="S1-E9" link="https://drive.google.com/open?id=1fk_jPc4INtdjDprzJ4xnqQp3vR3Kc0Rl&usp=drive_copy"/>
      <Ep ep="S1-E10 Log Horizon " s="S1-E10" link="https://drive.google.com/open?id=19lMVtwXQ4lipVOggIr4nXmKF5zXtMgph&usp=drive_copy"/>
      <Ep ep="S1-E11 Log Horizon " s="S1-E11" link="https://drive.google.com/open?id=1pgxj0lXaGM2Rbu2VHR3VLiigk6B6PeoL&usp=drive_copy"/>
      <Ep ep="S1-E12 Log Horizon " s="S1-E12" link="https://drive.google.com/open?id=1_B6QBDClDdIqudo2ezSIbFbnRjYyw20h&usp=drive_copy"/>
      <Ep ep="S1-E13 Log Horizon " s="S1-E13" link="https://drive.google.com/open?id=1qaumljLIBe3HMevdSfr2uDBywQ3M9fsK&usp=drive_copy"/>
      <Ep ep="S1-E14 Log Horizon " s="S1-E14" link="https://drive.google.com/open?id=1i-4uv8JDq2hBqt7WqcWnqLVo3z-ICrwp&usp=drive_copy"/>
      <Ep ep="S1-E15 Log Horizon " s="S1-E15" link="https://drive.google.com/open?id=1-canQPrzOoF19rqo2hQcdLvtmZhxk7Tf&usp=drive_copy"/>
      <Ep ep="S1-E16 Log Horizon " s="S1-E16" link="https://drive.google.com/open?id=119UdLMNsAZ1h9ZaYzOBKN3JuGQ1ReD3S&usp=drive_copy"/>
      <Ep ep="S1-E17 Log Horizon " s="S1-E17" link="https://drive.google.com/open?id=1CeWiz9CKlEcJihhaI110PL4yZROEsPdE&usp=drive_copy"/>
      <Ep ep="S1-E18 Log Horizon " s="S1-E18" link="https://drive.google.com/open?id=1Z7eS0-8LNi9_G-6F_m08wjCeaEEAe9bR&usp=drive_copy"/>
      <Ep ep="S1-E19 Log Horizon " s="S1-E19" link="https://drive.google.com/open?id=1g_QmqO4x2_MjzWCWjW-NA8aMAdN1y8-8&usp=drive_copy"/>
      <Ep ep="S1-E20 Log Horizon " s="S1-E20" link="https://drive.google.com/open?id=1kIBvanddY8wMW-Q0nP03wYXe138zqe3p&usp=drive_copy"/>
      <Ep ep="S1-E21 Log Horizon " s="S1-E21" link="https://drive.google.com/open?id=1ZRy-lnT4fD7oPg4usx0d8KU4ABbbkrFg&usp=drive_copy"/>
      <Ep ep="S1-E22 Log Horizon " s="S1-E22" link="https://drive.google.com/open?id=1MpQDbsIVH_2rC6kVE5gl1cjpbuMxbXAP&usp=drive_copy"/>
      <Ep ep="S1-E23 Log Horizon " s="S1-E23" link="https://drive.google.com/open?id=1Z0Mz1cgPBECyuydKAuLoQMh0ooCWxSUX&usp=drive_copy"/>
      <Ep ep="S1-E24 Log Horizon " s="S1-E24" link="https://drive.google.com/open?id=1KaM_7gSZlGTOmmWN8a1yrXKcSjdC102s&usp=drive_copy"/>
      <Ep ep="S1-E25 Log Horizon " s="S1-E25" link="https://drive.google.com/open?id=1Bbr0drgZoJoNEVd7V3sB64OmXdSAGuAJ&usp=drive_copy"/>
      
    <Animeover />
    <Footer />
  </>
  )
}

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaDiscord } from "react-icons/fa"; // Importing the Discord icon

const Navbars = () => {
  const [background, setBackground] = useState("");

  const changeWallpaper = (url) => {
    document.body.style.backgroundImage = `url('${url}')`;
  };

  return (
    <>
      <header className="relative top-7">
        <nav className="flex justify-center">
          <ul className="flex items-center justify-center space-x-9 h-14 w-112 bg-[#1e0a3c] border-blue-400 rounded-full border-2 text-white">
            <Link to="Home">
              <li className="transition ease-in-out hover:text-blue-400 hover:underline decoration-2 underline-offset-4">
                HOME
              </li>
            </Link>
            <Link to="About">
              <li className="transition ease-in-out hover:text-blue-400 hover:underline decoration-2 underline-offset-4">
                ABOUT
              </li>
            </Link>
            
            <Link to="/movies">
              <li className="transition ease-in-out hover:text-blue-400 hover:underline decoration-2 underline-offset-4">
                MOVIES
              </li>
            </Link>
            {/* Discord Icon Link */}
            <li>
              <a
                href="https://discord.gg/2JBnqk2kne"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-2 transition ease-in-out hover:text-blue-400 hover:underline decoration-2 underline-offset-4"
              >
                <FaDiscord className="text-xl" /> {/* Discord icon */}
                <span>Discord</span>
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <div className="flex justify-center mt-14 max-md:hidden">
        <Link
          to="#"
          className="text-white mr-2 font-bold underline-offset-4 decoration-4 text-base hover:text-slate-300 active:text-red-400 hover:underline"
          onClick={(e) => {
            e.preventDefault();
            changeWallpaper("https://i.postimg.cc/6p3Pg2rY/jujutsu-kaisen.jpg");
          }}
        >
          Jujutsu Kaisen
        </Link>
        <Link
          to="#"
          className="text-white mr-2 font-bold underline-offset-4 decoration-4 text-base hover:text-slate-300 active:text-red-400 hover:underline"
          onClick={(e) => {
            e.preventDefault();
            changeWallpaper("https://i.postimg.cc/50ckXsZR/one-piece.jpg");
          }}
        >
          One Piece
        </Link>
        <Link
          to="#"
          className="text-white mr-2 font-bold underline-offset-4 decoration-4 text-base hover:text-slate-300 active:text-red-400 hover:underline"
          onClick={(e) => {
            e.preventDefault();
            changeWallpaper(
              "https://i.postimg.cc/2yrcwVjd/kyojuro-rengoku.jpg"
            );
          }}
        >
          Demon Slayer
        </Link>
        <Link
          to="#"
          className="text-white mr-2 font-bold underline-offset-4 decoration-4 text-base hover:text-slate-300 active:text-red-400 hover:underline"
          onClick={(e) => {
            e.preventDefault();
            changeWallpaper("https://i.postimg.cc/cJ3FGQrM/montai.jpg");
          }}
        >
          Mountain
        </Link>
      </div>
    </>
  );
};

export default Navbars;

import React from 'react'

import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";

export default function Wind() {
  return (
    <>
 <Main img="https://i.postimg.cc/TY9QDNsP/MV5-BNTQz-NDI5-OGIt-ZDZk-My00-MWQ1-LWIw-M2-Yt-Yzc2-YWNh-OGJl-ZWQx-Xk-Ey-Xk-Fqc-Gc-V1-FMjpg-UX1000.jpg" name="Wind Breaker" s="1" e="13" />
  
 <Ep ep="S1-E1 Wind Breaker" s="S1-E1" link="https://drive.google.com/open?id=1ATe58MV_6oivam5B7GfPEF1C_42EGFEw&usp=drive_copy"/>
<Ep ep="S1-E2 Wind Breaker" s="S1-E2" link="https://drive.google.com/open?id=1b5EYC4M6VRge-yC63sXKRrvCEHVZIGzd&usp=drive_copy"/>
<Ep ep="S1-E3 Wind Breaker" s="S1-E3" link="https://drive.google.com/open?id=1taL5p1yEFxqAyIaICtQTKOL_NJ-p5sTj&usp=drive_copy"/>
<Ep ep="S1-E4 Wind Breaker" s="S1-E4" link="https://drive.google.com/open?id=14cYCMebeQlCCbet7P-SzI9UMpBoGmI56&usp=drive_copy"/>
<Ep ep="S1-E5 Wind Breaker" s="S1-E5" link="https://drive.google.com/open?id=1ND_hMuqCrf0mdlXTKP6QRXdmQPtDwumn&usp=drive_copy"/>
<Ep ep="S1-E6 Wind Breaker" s="S1-E6" link="https://drive.google.com/open?id=1JYCBn4EuYvt33vYoD65pAX3ZS91-Mt5Y&usp=drive_copy"/>
<Ep ep="S1-E7 Wind Breaker" s="S1-E7" link="https://drive.google.com/open?id=1tercQoLwbuMnXkUM5GPTFtis6f2NWbAw&usp=drive_copy"/>
<Ep ep="S1-E8 Wind Breaker" s="S1-E8" link="https://drive.google.com/open?id=1n26O1cYFEbI_38biJWVI_eV3-p69OzSr&usp=drive_copy"/>
<Ep ep="S1-E9 Wind Breaker" s="S1-E9" link="https://drive.google.com/open?id=1cjtL9lyQxJJWXpSikYVfMyEe5aOgn14D&usp=drive_copy"/>
<Ep ep="S1-E10 Wind Breaker" s="S1-E10" link="https://drive.google.com/open?id=1YzaXqfF5RRurv1DzbPXI8GVUELtUtkq_&usp=drive_copy"/>
<Ep ep="S1-E11 Wind Breaker" s="S1-E11" link="https://drive.google.com/open?id=1hcYPc7lxWZiALBZRUyyq-aoP0Og0fPPg&usp=drive_copy"/>
<Ep ep="S1-E12 Wind Breaker" s="S1-E12" link="https://drive.google.com/open?id=1nVKEy-MKEjbz2sN2Et77uHr6wHIUTpyZ&usp=drive_copy"/>
<Ep ep="S1-E13 Wind Breaker" s="S1-E13" link="https://drive.google.com/open?id=1xLTNWiVXetdip6TW7OlJEYQA6BZyVIWl&usp=drive_copy"/>


 <Animeover/>
 <Footer/>
    </>
  )
}

import React from "react";
import "./Main.css";
export default function Main(props) {
  return (
    <>
      <div className="main">
        <div className="main-img">
          <img src={props.img} alt="anime" />
        </div>
        <div className="main-name">
          <h1>{props.name}</h1>
          <br />
          <h2>
            {props.s} Seasons {props.e} Episodes
          </h2>
          <br />
          <h3>Quality: (1080p)</h3>
        </div>
        <div className="main-nav">
          <ul>
            <li>Animation</li>
            <li>Drama</li>
            <li>crunchyroll</li>
            <li>Hindi</li>
            <li>Sci-Fi</li>
            <li>Fantasy</li>
          </ul>
        </div>
      </div>
      <div className="ep-main">
        <div className="ep-head">
          <img src={props.img} alt="anime" />
          <h3>Seasons {props.s}</h3>
          <p>Episodes {props.e}-2023</p>
        </div>
      </div>
    </>
  );
}

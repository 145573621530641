import React from 'react'

import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";

export default function Rankof() {
  return (
    <>
 <Main img="https://i.postimg.cc/JnVjgjFg/MV5-BNj-M3-OWRm-ODQt-ODVj-YS00-Yz-Y5-LWFi-Nm-Qt-OGEx-M2-M4-OWQ3-Nzk1-Xk-Ey-Xk-Fqc-Gc-V1-FMjpg-UX1000.jpg" name="Ranking of Kings" s="1" e="23" />
 
 <Ep ep="S1-E1 Ranking of Kings" s="S1-E1" link="https://drive.google.com/open?id=1IBBcGQB_K3u1XgFt8H-WFCHxAeMTFDZe&usp=drive_copy"/>
<Ep ep="S1-E2 Ranking of Kings" s="S1-E2" link="https://drive.google.com/open?id=1CRUzNYIpnloFQBOEl5PNdMxRQ6EQxeAt&usp=drive_copy"/>
<Ep ep="S1-E3 Ranking of Kings" s="S1-E3" link="https://drive.google.com/open?id=1a0bfJssWyf5CrEPKuYHK7a3-7iQL09zp&usp=drive_copy"/>
<Ep ep="S1-E4 Ranking of Kings" s="S1-E4" link="https://drive.google.com/open?id=1SIy2dMI2wqh7_kD7q_wNyvCFsrCBetWr&usp=drive_copy"/>
<Ep ep="S1-E5 Ranking of Kings" s="S1-E5" link="https://drive.google.com/open?id=1YWfsIBx0b4nR9mWOVmKWNwEGbOk0oUY1&usp=drive_copy"/>
<Ep ep="S1-E6 Ranking of Kings" s="S1-E6" link="https://drive.google.com/open?id=1t8BvnQ1MditfDVfLOJrl-hQEAdZ66HBB&usp=drive_copy"/>
<Ep ep="S1-E7 Ranking of Kings" s="S1-E7" link="https://drive.google.com/open?id=1lZUTw9GKz8--xr5QDJK42DI-R8G5mHeP&usp=drive_copy"/>
<Ep ep="S1-E8 Ranking of Kings" s="S1-E8" link="https://drive.google.com/open?id=1kgAbNrnI4dyvG0JsBHIBKbQRw9KMDPhn&usp=drive_copy"/>
<Ep ep="S1-E9 Ranking of Kings" s="S1-E9" link="https://drive.google.com/open?id=1awaViHaTr7dpvPH8IMc3_95JAmf62YAC&usp=drive_copy"/>
<Ep ep="S1-E10 Ranking of Kings" s="S1-E10" link="https://drive.google.com/open?id=1F4xUQfrQIWCDKz-Z07RPaFMSD3GPgBNF&usp=drive_copy"/>
<Ep ep="S1-E11 Ranking of Kings" s="S1-E11" link="https://drive.google.com/open?id=1FuSbuTrP73F6p7L_FaoqkTlsoc5fRdwr&usp=drive_copy"/>
<Ep ep="S1-E12 Ranking of Kings" s="S1-E12" link="https://drive.google.com/open?id=1Fhgn9Zh6z7-dZaMG5jDQz4M6mlsmEioH&usp=drive_copy"/>
<Ep ep="S1-E13 Ranking of Kings" s="S1-E13" link="https://drive.google.com/open?id=1uXMXtMXNUNFC7yRpXWKhUn_Tw6x3c-IA&usp=drive_copy"/>
<Ep ep="S1-E14 Ranking of Kings" s="S1-E14" link="https://drive.google.com/open?id=1j-om6oG5Up4TyS4hJ49nGEpUFmoW-kQE&usp=drive_copy"/>
<Ep ep="S1-E15 Ranking of Kings" s="S1-E15" link="https://drive.google.com/open?id=1HmdKExY2e7hQQWLjVxjmikyOa2I7gCjK&usp=drive_copy"/>
<Ep ep="S1-E16 Ranking of Kings" s="S1-E16" link="https://drive.google.com/open?id=1KEb3ccnYADTlTFvYkhqib8cRy7gKLW_R&usp=drive_copy"/>
<Ep ep="S1-E17 Ranking of Kings" s="S1-E17" link="https://drive.google.com/open?id=1f_BscA0VBsBIm7B-UMAWHS2biRaqOmR5&usp=drive_copy"/>
<Ep ep="S1-E18 Ranking of Kings" s="S1-E18" link="https://drive.google.com/open?id=1Ub95TrnDZYFN-gdIqHmTiLamugD5nZOZ&usp=drive_copy"/>
<Ep ep="S1-E19 Ranking of Kings" s="S1-E19" link="https://drive.google.com/open?id=1h_OnbtlQzSTCJKcdDvW85TdiGBSbQWhV&usp=drive_copy"/>
<Ep ep="S1-E20 Ranking of Kings" s="S1-E20" link="https://drive.google.com/open?id=1wb3TBZQ82UJygrFEHNGzDl4Dpk_Fn_kZ&usp=drive_copy"/>
<Ep ep="S1-E21 Ranking of Kings" s="S1-E21" link="https://drive.google.com/open?id=1NcHW_-_M1tnf2bQ7eVq73iY_z8Ok2_Du&usp=drive_copy"/>
<Ep ep="S1-E22 Ranking of Kings" s="S1-E22" link="https://drive.google.com/open?id=1nAsenMhJQZbzhsL3gHvxiSvovWFbq_Wz&usp=drive_copy"/>
<Ep ep="S1-E23 Ranking of Kings" s="S1-E23" link="https://drive.google.com/open?id=199jUYrqq7VXpx9cWwNgEH1_4g1qFDuiH&usp=drive_copy"/>


 <Animeover/>
<Footer/>
</>
  )
}

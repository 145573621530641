import React from 'react'

export default function Tell() {
  return (
    <>
     <div className='flex justify-center mt-10'>
           <div className='h-20 w-auto flex justify-center items-center backdrop-blur rounded-t-lgdis border rounded border-whitegood shadow shadow-black'>
                <h3 className='text-white font-bold text-base'>Notice!! Some Anime Are not coming In (FHD) For Streaming So plz Download The Anime for Good Quietly</h3>
           </div>
     </div>
    </>
  )
}
